import { toString, Record, Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, record_type, string_type, option_type, anonRecord_type, array_type, uint8_type, bool_type, class_type, list_type, union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ShiftLogTypeModule_toString, ShiftComprehensive, ShiftDetail_$reflection, ShiftLog_$reflection, ShiftComprehensive_$reflection } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getShiftPdf, getAllShiftLogRecords, getShift } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { getShiftJobImage, getShiftJobImageThumbnail } from "../../../AppDomain/UseCase/Job.fs.js";
import { op_Division, fromParts } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { LocationModule_getDistanceInMeters, Location } from "../../../../Logos.Shared/Domain/Entity/Location.fs.js";
import { compare, now, minValue } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { sortByDescending, tryFind, ofArray, singleton as singleton_1, append, map, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { round, createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { DateTime_timeToString, DateTime_dateTimeToString, DateTime_dateTimeToStringWithDayName, HttpUtil_saveByteArray } from "../../../AppDomain/Util.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { toBase64String, join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { alignCellTextTop, Daisy_progressState, Daisy_error, printButton, Daisy_h4, Daisy_DialogWidth, Daisy_h6 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { String_toTitleCase, System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes } from "../../../../Logos.Shared/Util.fs.js";
import { map as map_1, empty as empty_1, append as append_1, singleton as singleton_2, collect, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { showModalDialog } from "../../Component/ModalDialog.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ShiftLogs_toMapMarker, Circle, maps } from "../../Component/Map.fs.js";

export class Tab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Map", "ShiftPreChecks", "ShiftPostChecks", "JobImages"];
    }
}

export function Tab_$reflection() {
    return union_type("Presentation.Pages.ShiftEdit.Tab", [], Tab, () => [[], [], [], []]);
}

export class State extends Record {
    constructor(Shift, ShiftLogs, TotalWorkDuration, TotalBreakDuration, IsEditHistoryDialogVisible, ShiftLogEditHistory, ActiveTab, Thumbnails, IsShowingImageModalDialog, SelectedImageToShowAsModal, IsBusy, Errors) {
        super();
        this.Shift = Shift;
        this.ShiftLogs = ShiftLogs;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
        this.IsEditHistoryDialogVisible = IsEditHistoryDialogVisible;
        this.ShiftLogEditHistory = ShiftLogEditHistory;
        this.ActiveTab = ActiveTab;
        this.Thumbnails = Thumbnails;
        this.IsShowingImageModalDialog = IsShowingImageModalDialog;
        this.SelectedImageToShowAsModal = SelectedImageToShowAsModal;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.ShiftEdit.State", [], State, () => [["Shift", ShiftComprehensive_$reflection()], ["ShiftLogs", list_type(ShiftLog_$reflection())], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")], ["IsEditHistoryDialogVisible", bool_type], ["ShiftLogEditHistory", list_type(ShiftLog_$reflection())], ["ActiveTab", Tab_$reflection()], ["Thumbnails", list_type(anonRecord_type(["Image", array_type(uint8_type)], ["ShiftJobImageId", class_type("System.Guid")]))], ["IsShowingImageModalDialog", bool_type], ["SelectedImageToShowAsModal", option_type(array_type(uint8_type))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetShiftResponse", "GetShiftEditHistoryResponse", "GetImageThumbnailResponse", "GetImageResponse", "ShowImageDialog", "HideImage", "ShowEditHistoryDialog", "HideEditHistoryDialog", "SetTab", "GetShiftPdf", "GetShiftPdfResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.ShiftEdit.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(ShiftDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(ShiftDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ShiftLog_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ShiftLog_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(class_type("System.Guid"), array_type(uint8_type)), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(class_type("System.Guid"), array_type(uint8_type))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Guid")]], [], [["Item", class_type("System.Guid")]], [], [["Item", Tab_$reflection()]], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getShiftCmd(shiftId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(11, [Item_1])), (dispatch) => singleton.Delay(() => getShift(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), shiftId)));
}

export function getAllShiftLogRecordsCmd(shiftLogId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(11, [Item_1])), (dispatch) => singleton.Delay(() => getAllShiftLogRecords(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), shiftLogId)));
}

export function getShiftPdfCmd(shiftId) {
    return cmdOfAsync((Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(11, [Item_1])), (dispatch) => singleton.Delay(() => getShiftPdf(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), shiftId)));
}

export function getJobImageThumbnailCmd(shiftJobImageId) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(11, [Item_1])), () => singleton.Delay(() => {
        const x = getShiftJobImageThumbnail(appRoot.JobService, AppRootModule_getSelectedOrgId(), shiftJobImageId);
        return singleton.Bind(x, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [[shiftJobImageId, input_2.fields[0]]])));
            return singleton.Return(value);
        });
    }));
}

export function getJobImageCmd(shiftJobImageId) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(11, [Item_1])), () => singleton.Delay(() => getShiftJobImage(appRoot.JobService, AppRootModule_getSelectedOrgId(), shiftJobImageId)));
}

export function init(shiftId) {
    return [new State(new ShiftComprehensive("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", "00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", "", new Location(fromParts(0, 0, 0, false, 1), fromParts(0, 0, 0, false, 1), undefined), 0, minValue(), undefined, undefined, false, "", empty(), empty(), empty(), false, "", minValue(), undefined), empty(), 0, 0, false, empty(), new Tab(0, []), empty(), false, undefined, new Busy(1, []), empty()), getShiftCmd(shiftId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, true, msg.fields[0].fields[0], state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 6:
            return [state, getAllShiftLogRecordsCmd(msg.fields[0])];
        case 7:
            return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, false, empty(), state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, msg.fields[0], state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.IsBusy, state.Errors), equals(msg.fields[0], new Tab(3, [])) ? Cmd_batch(map((x_1) => getJobImageThumbnailCmd(x_1.Id), state.Shift.ShiftJobImages)) : Cmd_none()];
        case 9:
            return [state, getShiftPdfCmd(msg.fields[0])];
        case 10:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                HttpUtil_saveByteArray(msg.fields[0].fields[0], `Shift-${state.Shift.Username}-${DateTime_dateTimeToStringWithDayName(state.Shift.Created)}`, "application/pdf");
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, append(state.Thumbnails, singleton_1({
                    Image: msg.fields[0].fields[0][1],
                    ShiftJobImageId: msg.fields[0].fields[0][0],
                })), state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, false, state.SelectedImageToShowAsModal, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, msg.fields[0].fields[0], state.IsBusy, state.Errors), Cmd_none()];
            }
        case 4:
            return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, true, state.SelectedImageToShowAsModal, state.IsBusy, state.Errors), getJobImageCmd(msg.fields[0])];
        case 5:
            return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, false, undefined, state.IsBusy, state.Errors), Cmd_none()];
        case 11:
            return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Shift, state.ShiftLogs, state.TotalWorkDuration, state.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const shiftDetail = msg.fields[0].fields[0];
                return [new State(shiftDetail.Shift, shiftDetail.ShiftLogs, shiftDetail.TotalWorkDuration, shiftDetail.TotalBreakDuration, state.IsEditHistoryDialogVisible, state.ShiftLogEditHistory, state.ActiveTab, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function shiftTable(shift, workDuration, breakDuration) {
    let elems, children, children_2, children_4, children_6, children_8, children_10, children_12;
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "w-full"])], (elems = [(children = singleton_1(Daisy_h6("Job")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("div", {
        children: shift.JobTitle,
    }), (children_2 = singleton_1(Daisy_h6("Created")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("div", {
        children: DateTime_dateTimeToStringWithDayName(shift.Created),
    }), (children_4 = singleton_1(Daisy_h6("Status")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), createElement("div", {
        children: shift.IsFinished ? "Finished" : "In Progress",
    }), (children_6 = singleton_1(Daisy_h6("Work Duration (H:M)")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), createElement("div", {
        children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(workDuration),
    }), (children_8 = singleton_1(Daisy_h6("Break Duration (H:M)")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), createElement("div", {
        children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(breakDuration),
    }), (children_10 = singleton_1(Daisy_h6("Geofence radius")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    })), createElement("div", {
        children: shift.JobGeofenceRadius,
    }), (children_12 = singleton_1(Daisy_h6("OS & App Version")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), createElement("div", {
        children: `${String_toTitleCase(shift.Os)} ${shift.AppVersion}`,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function editTimeLine(shiftLogs, dispatch) {
    let elems_2, elems_1;
    const timelineBody = createElement("div", createObj(singleton_1((elems_2 = [Daisy_h6("Shift Log Edit History"), createElement("ol", createObj(ofArray([["className", "list-decimal pl-4"], (elems_1 = toList(delay(() => collect((shiftLog) => {
        let elems;
        let logDateTime;
        const matchValue = shiftLog.ShiftLogType;
        logDateTime = ((matchValue.tag === 1) ? matchValue.fields[0].StartTime : ((matchValue.tag === 2) ? matchValue.fields[0].StartTime : ((matchValue.tag === 3) ? matchValue.fields[0].FinishTime : ((matchValue.tag === 4) ? matchValue.fields[0].FinishTime : now()))));
        return singleton_2(createElement("li", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [Daisy_h6(ShiftLogTypeModule_toString(shiftLog.ShiftLogType)), createElement("p", {
            children: DateTime_dateTimeToString(logDateTime),
        }), createElement("p", {
            children: `[Created: ${DateTime_dateTimeToString(shiftLog.Created)}]`,
        }), createElement("p", {
            children: shiftLog.Comment,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
    }, shiftLogs))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))));
    return createElement(showModalDialog, {
        dialogId: "shift-log-edit-history-button",
        header: "",
        width: new Daisy_DialogWidth(0, []),
        body: () => timelineBody,
        onShow: () => {
        },
        onClose: () => {
            dispatch(new Msg(7, []));
        },
    });
}

export class TimelineItemType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Start", "Middle", "End"];
    }
}

export function TimelineItemType_$reflection() {
    return union_type("Presentation.Pages.ShiftEdit.TimelineItemType", [], TimelineItemType, () => [[], [], []]);
}

const hr = createElement("hr", {
    className: join(" ", ["bg-purple-500"]),
});

export function shiftLogTimelineElement(timelineItemType, title, dateTime, createdDateTime, comment, jobGeofenceRadius, distanceFromJobLocation, shiftLogId, shiftLogVersion, dispatch) {
    const children_4 = toList(delay(() => {
        let matchValue;
        return append_1((matchValue = timelineItemType, (matchValue.tag === 1) ? singleton_2(hr) : ((matchValue.tag === 2) ? singleton_2(hr) : singleton_2(react.createElement(react.Fragment, {})))), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("timeline-middle", singleton_1(["className", join(" ", ["fa fa-solid fa-circle", "text-purple-500"])]))))), delay(() => {
            let elems_6;
            return append_1(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["timeline-end mb-10"])], (elems_6 = toList(delay(() => {
                let elems_2;
                const distanceKmMessage = ((distanceFromJobLocation > jobGeofenceRadius) && (distanceFromJobLocation > 0)) ? (`${op_Division(new Decimal(distanceFromJobLocation), fromParts(1000, 0, 0, false, 0))} Km from Job Location`) : "";
                return append_1((distanceFromJobLocation > jobGeofenceRadius) ? singleton_2(createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", `${distanceKmMessage} Km from Job Location`], (elems_2 = [createElement("i", {
                    className: join(" ", ["fa", "fa-flag"]),
                    style: {
                        marginRight: "8px",
                        color: "red",
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))) : empty_1(), delay(() => {
                    let elems_3;
                    return append_1((shiftLogVersion > 1) ? singleton_2(createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", `Shift log edited - Edit count ${shiftLogVersion - 1}`], (elems_3 = [createElement("i", {
                        className: join(" ", ["fa", "fa-flag"]),
                        style: {
                            marginRight: "8px",
                            color: "orange",
                        },
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))) : empty_1(), delay(() => append_1(singleton_2(Daisy_h6(title)), delay(() => append_1(singleton_2(createElement("p", {
                        children: DateTime_dateTimeToString(dateTime),
                    })), delay(() => {
                        let elems_4;
                        return append_1(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-between", "items-center"])], (elems_4 = toList(delay(() => append_1(singleton_2(createElement("p", {
                            children: `[Created: ${DateTime_dateTimeToString(createdDateTime)}]`,
                        })), delay(() => {
                            let elems_1, elems;
                            return (shiftLogVersion > 1) ? singleton_2(createElement("div", createObj(Helpers_combineClasses("tooltip", singleton_1((elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems = [createElement("i", {
                                className: join(" ", ["fa", "fa-history"]),
                            })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg) => {
                                dispatch(new Msg(6, [shiftLogId]));
                            }]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))))) : empty_1();
                        })))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                            let elems_5;
                            return append_1((distanceFromJobLocation > jobGeofenceRadius) ? singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["text-red-500"])], (elems_5 = [createElement("p", {
                                children: distanceKmMessage,
                            })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty_1(), delay(() => singleton_2(createElement("p", {
                                children: comment,
                            }))));
                        }));
                    }))))));
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                const matchValue_1 = timelineItemType;
                return (matchValue_1.tag === 1) ? singleton_2(hr) : ((matchValue_1.tag === 2) ? singleton_2(react.createElement(react.Fragment, {})) : singleton_2(hr));
            }));
        }))));
    }));
    return createElement("li", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function shiftLogsTimeline(jobGeofenceRadius, shiftLogs, dispatch) {
    let elems;
    return createElement("ul", createObj(Helpers_combineClasses("timeline", ofArray([["className", "timeline-vertical"], (elems = toList(delay(() => collect((shiftLog) => {
        const matchValue = shiftLog.ShiftLogType;
        return (matchValue.tag === 1) ? singleton_2(shiftLogTimelineElement(new TimelineItemType(0, []), "Work Started", matchValue.fields[0].StartTime, shiftLog.Created, shiftLog.Comment, jobGeofenceRadius, shiftLog.DistanceFromJobLocation, shiftLog.Id, shiftLog.Version, dispatch)) : ((matchValue.tag === 2) ? singleton_2(shiftLogTimelineElement(new TimelineItemType(1, []), "Break Started", matchValue.fields[0].StartTime, shiftLog.Created, shiftLog.Comment, jobGeofenceRadius, shiftLog.DistanceFromJobLocation, shiftLog.Id, shiftLog.Version, dispatch)) : ((matchValue.tag === 3) ? singleton_2(shiftLogTimelineElement(new TimelineItemType(1, []), "Break Finished", matchValue.fields[0].FinishTime, shiftLog.Created, shiftLog.Comment, jobGeofenceRadius, shiftLog.DistanceFromJobLocation, shiftLog.Id, shiftLog.Version, dispatch)) : ((matchValue.tag === 4) ? singleton_2(shiftLogTimelineElement(new TimelineItemType(2, []), "Work Finished", matchValue.fields[0].FinishTime, shiftLog.Created, shiftLog.Comment, jobGeofenceRadius, shiftLog.DistanceFromJobLocation, shiftLog.Id, shiftLog.Version, dispatch)) : singleton_2(react.createElement(react.Fragment, {})))));
    }, shiftLogs))), ["children", reactApi.Children.toArray(Array.from(elems))])]))));
}

export function getTabProps(viewTab, state, dispatch) {
    const getTabProp = (title, viewTabA) => append(ofArray([["children", title], ["onClick", (_arg) => {
        dispatch(new Msg(8, [viewTabA]));
    }]]), toList(delay(() => (equals(state.ActiveTab, viewTabA) ? singleton_2(["className", "tab-active"]) : empty_1()))));
    switch (viewTab.tag) {
        case 1:
            return getTabProp("Pre-Checks", new Tab(1, []));
        case 2:
            return getTabProp("Post-Checks", new Tab(2, []));
        case 3:
            return getTabProp("Job Images", new Tab(3, []));
        default:
            return getTabProp("Map", new Tab(0, []));
    }
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.shiftId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => {
        let elems;
        return append_1(singleton_2(createElement("div", createObj(ofArray([["className", "flex justify-between"], (elems = [Daisy_h4((`Shift - ${state_1.Shift.Username}`).trim()), printButton((_arg_2) => {
            dispatch(new Msg(9, [props.shiftId]));
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => append_1(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_1;
            return append_1(singleton_2((children_1 = singleton_1(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_1)),
            }))), delay(() => append_1(state_1.IsEditHistoryDialogVisible ? singleton_2(editTimeLine(state_1.ShiftLogEditHistory, dispatch)) : singleton_2(react.createElement(react.Fragment, {})), delay(() => append_1(state_1.IsShowingImageModalDialog ? singleton_2(createElement(showModalDialog, {
                dialogId: `${state_1.Shift.Id} - Image`,
                header: "",
                width: new Daisy_DialogWidth(4, []),
                heightPercent: "80",
                body: () => {
                    const matchValue = state_1.SelectedImageToShowAsModal;
                    if (matchValue == null) {
                        const children_4 = singleton_1(createElement("progress", createObj(Helpers_combineClasses("progress", singleton_1(["className", "progress-primary"])))));
                        return createElement("div", {
                            children: reactApi.Children.toArray(Array.from(children_4)),
                        });
                    }
                    else {
                        return createElement("img", {
                            src: "data:image/jpg;base64," + toBase64String(matchValue),
                            style: {
                                width: 100 + "%",
                                height: 100 + "%",
                            },
                        });
                    }
                },
                onShow: () => {
                },
                onClose: () => {
                    dispatch(new Msg(5, []));
                },
            })) : empty_1(), delay(() => {
                let elems_4, elems_2, elems_3;
                return append_1(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-4"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems_2 = [shiftTable(state_1.Shift, state_1.TotalWorkDuration, state_1.TotalBreakDuration)], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2", "flex", "justify-start"])], (elems_3 = [shiftLogsTimeline(state_1.Shift.JobGeofenceRadius, state_1.ShiftLogs, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_5;
                    return append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("tabs", ofArray([["className", "tabs-lifted"], (elems_5 = [createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(0, []), state_1, dispatch)))), createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(1, []), state_1, dispatch)))), createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(2, []), state_1, dispatch)))), createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(3, []), state_1, dispatch))))], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))))), delay(() => {
                        let elems_6, children_13, children_11, children_17, elems_7, children_22, children_20, children_26, elems_10, children_31, children_29, children_36;
                        const matchValue_1 = state_1.ActiveTab;
                        return (matchValue_1.tag === 1) ? singleton_2(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_13 = singleton_1((children_11 = ofArray([createElement("th", {
                            width: 20 + "%",
                            children: "Name",
                        }), createElement("th", {
                            width: 50 + "%",
                            children: "Description",
                        }), createElement("th", {
                            width: 30 + "%",
                            children: "Notes",
                        })]), createElement("tr", {
                            children: reactApi.Children.toArray(Array.from(children_11)),
                        }))), createElement("thead", {
                            children: reactApi.Children.toArray(Array.from(children_13)),
                        })), (children_17 = toList(delay(() => map_1((preCheckItem) => {
                            const children_15 = ofArray([createElement("td", {
                                children: preCheckItem.Name,
                            }), createElement("td", {
                                children: preCheckItem.Description,
                            }), createElement("td", {
                                children: preCheckItem.Notes,
                            })]);
                            return createElement("tr", {
                                children: reactApi.Children.toArray(Array.from(children_15)),
                            });
                        }, state_1.Shift.ShiftPreCheckItems))), createElement("tbody", {
                            children: reactApi.Children.toArray(Array.from(children_17)),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))) : ((matchValue_1.tag === 2) ? singleton_2(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_7 = [(children_22 = singleton_1((children_20 = ofArray([createElement("th", {
                            width: 20 + "%",
                            children: "Name",
                        }), createElement("th", {
                            width: 50 + "%",
                            children: "Description",
                        }), createElement("th", {
                            width: 30 + "%",
                            children: "Notes",
                        })]), createElement("tr", {
                            children: reactApi.Children.toArray(Array.from(children_20)),
                        }))), createElement("thead", {
                            children: reactApi.Children.toArray(Array.from(children_22)),
                        })), (children_26 = toList(delay(() => map_1((postCheckItem) => {
                            const children_24 = ofArray([createElement("td", {
                                children: postCheckItem.Name,
                            }), createElement("td", {
                                children: postCheckItem.Description,
                            }), createElement("td", {
                                children: postCheckItem.Notes,
                            })]);
                            return createElement("tr", {
                                children: reactApi.Children.toArray(Array.from(children_24)),
                            });
                        }, state_1.Shift.ShiftPostCheckItems))), createElement("tbody", {
                            children: reactApi.Children.toArray(Array.from(children_26)),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))))) : ((matchValue_1.tag === 3) ? singleton_2(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_10 = [(children_31 = singleton_1((children_29 = ofArray([createElement("th", {
                            width: 15 + "%",
                            children: "Date",
                        }), createElement("th", {
                            width: 15 + "%",
                            children: "Image",
                        }), createElement("th", {
                            width: 70 + "%",
                            children: "Note",
                        }), createElement("th", {
                            width: 10 + "%",
                            children: "Distance from Job (Km)",
                        })]), createElement("tr", {
                            children: reactApi.Children.toArray(Array.from(children_29)),
                        }))), createElement("thead", {
                            children: reactApi.Children.toArray(Array.from(children_31)),
                        })), (children_36 = toList(delay(() => map_1((image_1) => {
                            let elems_9, children_34, input_1, matchValue_3;
                            return createElement("tr", createObj(ofArray([["className", join(" ", ["hover"])], (elems_9 = [createElement("td", createObj(ofArray([alignCellTextTop, ["children", DateTime_timeToString(toLocalTime(image_1.Created))]]))), (children_34 = toList(delay(() => {
                                let elems_8;
                                const matchValue_2 = tryFind((x_7) => (x_7.ShiftJobImageId === image_1.Id), state_1.Thumbnails);
                                if (matchValue_2 == null) {
                                    return singleton_2(createElement("progress", createObj(Helpers_combineClasses("progress", singleton_1(["className", "progress-primary"])))));
                                }
                                else {
                                    const x_8 = matchValue_2;
                                    return singleton_2(createElement("div", createObj(ofArray([["style", {
                                        position: "relative",
                                        display: "inline-block",
                                    }], (elems_8 = [createElement("img", {
                                        src: "data:image/jpg;base64," + toBase64String(x_8.Image),
                                        style: {
                                            width: 100 + "px",
                                            height: 100 + "px",
                                        },
                                        title: "Click to view in modal",
                                    }), createElement("i", {
                                        className: "fas fa-search-plus",
                                        style: {
                                            position: "absolute",
                                            top: 5 + "px",
                                            right: 5 + "px",
                                            color: "white",
                                            fontSize: 12 + "px",
                                            cursor: "pointer",
                                        },
                                        onClick: (_arg_6) => {
                                            dispatch(new Msg(4, [x_8.ShiftJobImageId]));
                                        },
                                    })], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))));
                                }
                            })), createElement("td", {
                                children: reactApi.Children.toArray(Array.from(children_34)),
                            })), createElement("td", createObj(ofArray([alignCellTextTop, ["children", image_1.Note]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", `${round(defaultArg((input_1 = image_1.Location, (input_1 == null) ? undefined : ((matchValue_3 = LocationModule_getDistanceInMeters(state_1.Shift.JobLocation, input_1), (matchValue_3.tag === 1) ? 0 : matchValue_3.fields[0]))), 0) / 1000, 2)}`]])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
                        }, sortByDescending((x_11) => toLocalTime(x_11.Created), state_1.Shift.ShiftJobImages, {
                            Compare: compare,
                        })))), createElement("tbody", {
                            children: reactApi.Children.toArray(Array.from(children_36)),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))))) : singleton_2(maps(5, false, true, singleton_1(new Circle(state_1.Shift.JobLocation, state_1.Shift.JobGeofenceRadius)), map(ShiftLogs_toMapMarker, state_1.ShiftLogs)))));
                    }));
                }));
            }))))));
        }))))));
    })));
}

