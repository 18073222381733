import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { anonRecord_type, union_type, tuple_type, list_type, record_type, option_type, bool_type, string_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { LeaveRequestTypeModule_toString, UserRole_$reflection, LeaveRequest, LeaveRequestType_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { JobDetail, JobDetail_$reflection, UserJobOverrides, UserJobOverride } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { parse, newGuid } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { length, take, choose, ofArray, item, cons, isEmpty, exists, collect, filter, sortBy, tryFind, singleton, concat, empty, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { now as now_2, parse as parse_1, fromDateTimeOffset, compare, date as date_2, op_Subtraction, addDays, equals } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { empty as empty_1, collect as collect_1, map as map_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { rangeDouble } from "../../../fable_modules/fable-library-js.4.19.3/Range.js";
import { days as days_1, totalDays } from "../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { toLocalTime, fromDate, now as now_1, date as date_1, op_Subtraction as op_Subtraction_1, minValue, toUniversalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { Pagination_$reflection } from "../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { saveUserJobOverrides, getUserJobOverrides as getUserJobOverrides_1 } from "../../../AppDomain/UseCase/Job.fs.js";
import { createObj, dateHash, equals as equals_1, comparePrimitives } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { paginationControl, firstPage, nextPage, previousPage, getDefaultPagination } from "../../Component/Pagination.fs.js";
import { LocalUserDataModule_getUserDetails } from "../../../AppDomain/SystemService.fs.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { DateTime_dateToStringWithDayNameNoYear } from "../../../AppDomain/Util.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { isNullOrEmpty, join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_h7, Daisy_progressState, Daisy_error, Daisy_info, Daisy_h4, alignCellTextTop } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";

export class UserJobOverrideVM extends Record {
    constructor(Id, TempId, OrgId, DateTime, JobId, JobTitle, UserId, Username, IsModified, IsDeleted, LeaveRequestType) {
        super();
        this.Id = Id;
        this.TempId = TempId;
        this.OrgId = OrgId;
        this.DateTime = DateTime;
        this.JobId = JobId;
        this.JobTitle = JobTitle;
        this.UserId = UserId;
        this.Username = Username;
        this.IsModified = IsModified;
        this.IsDeleted = IsDeleted;
        this.LeaveRequestType = LeaveRequestType;
    }
}

export function UserJobOverrideVM_$reflection() {
    return record_type("Presentation.Pages.UserJobOverrideEdit.UserJobOverrideVM", [], UserJobOverrideVM, () => [["Id", class_type("System.Guid")], ["TempId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["DateTime", class_type("System.DateTimeOffset")], ["JobId", class_type("System.Guid")], ["JobTitle", string_type], ["UserId", class_type("System.Guid")], ["Username", string_type], ["IsModified", bool_type], ["IsDeleted", bool_type], ["LeaveRequestType", option_type(LeaveRequestType_$reflection())]]);
}

export function UserJobOverrideVMModule_toUserJobOverride(dto) {
    return new UserJobOverride(dto.Id, dto.OrgId, dto.DateTime, dto.JobId, dto.UserId, dto.Username);
}

export function UserJobOverrideVMModule_ofUserJobOverride(jobTitle, leaveRequestType, dto) {
    return new UserJobOverrideVM(dto.Id, newGuid(), dto.OrgId, dto.DateTime, dto.JobId, jobTitle, dto.UserId, dto.Username, false, false, leaveRequestType);
}

export class UserJobOverridesVM extends Record {
    constructor(UserId, Username, UserIsActive, UserJobOverrides) {
        super();
        this.UserId = UserId;
        this.Username = Username;
        this.UserIsActive = UserIsActive;
        this.UserJobOverrides = UserJobOverrides;
    }
}

export function UserJobOverridesVM_$reflection() {
    return record_type("Presentation.Pages.UserJobOverrideEdit.UserJobOverridesVM", [], UserJobOverridesVM, () => [["UserId", class_type("System.Guid")], ["Username", string_type], ["UserIsActive", bool_type], ["UserJobOverrides", list_type(UserJobOverrideVM_$reflection())]]);
}

export function UserJobOverridesVMModule_toUserJobOverrides(dto) {
    return new UserJobOverrides(dto.UserId, dto.Username, dto.UserIsActive, map(UserJobOverrideVMModule_toUserJobOverride, dto.UserJobOverrides), empty());
}

export function UserJobOverridesVMModule_ofUserJobOverrides(jobs, dto) {
    const userLeaves = concat(map((x) => {
        if (equals(x.StartDate, x.FinishDate)) {
            return singleton(x);
        }
        else {
            return map((y) => {
                const leaveDate = addDays(x.StartDate, y);
                return new LeaveRequest(x.Id, x.OrgId, x.UserId, leaveDate, leaveDate, x.Status, x.LeaveRequestType, x.Reason, x.PayrollAdminComment, x.PayrollAdminCommentUser, x.Created, x.Modified);
            }, toList(rangeDouble(0, 1, ~~totalDays(op_Subtraction(x.FinishDate, x.StartDate)))));
        }
    }, dto.UserLeaves));
    return new UserJobOverridesVM(dto.UserId, dto.Username, dto.UserIsActive, map((x_2) => {
        let input_1, input_3;
        return UserJobOverrideVMModule_ofUserJobOverride(defaultArg((input_1 = tryFind((y_1) => (y_1.Id === x_2.JobId), jobs), (input_1 == null) ? undefined : input_1.Title), ""), (input_3 = tryFind((y_3) => equals(y_3.StartDate, toUniversalTime(x_2.DateTime)), userLeaves), (input_3 == null) ? undefined : input_3.LeaveRequestType), x_2);
    }, dto.UserJobOverrides));
}

export class State extends Record {
    constructor(Jobs, UserJobOverridesToUpsert, DateFrom, DateTo, SelectedJobId, NextPagination, CurrentPagination, IsPaginationDisabled, UserRole, IsBusy, Errors) {
        super();
        this.Jobs = Jobs;
        this.UserJobOverridesToUpsert = UserJobOverridesToUpsert;
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.SelectedJobId = SelectedJobId;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.IsPaginationDisabled = IsPaginationDisabled;
        this.UserRole = UserRole;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.UserJobOverrideEdit.State", [], State, () => [["Jobs", list_type(JobDetail_$reflection())], ["UserJobOverridesToUpsert", list_type(UserJobOverridesVM_$reflection())], ["DateFrom", class_type("System.DateTimeOffset")], ["DateTo", class_type("System.DateTimeOffset")], ["SelectedJobId", class_type("System.Guid")], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["IsPaginationDisabled", bool_type], ["UserRole", UserRole_$reflection()], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetUserJobOverrides", "GetUserJobOverridesResponse", "SetUserJobOverride", "SetSelectedJobId", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "SaveUserJobOverrides", "SaveUserJobOverridesResponse", "SetDateRange", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.UserJobOverrideEdit.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(JobDetail_$reflection()), list_type(UserJobOverridesVM_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(JobDetail_$reflection()), list_type(UserJobOverridesVM_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", list_type(UserJobOverrideVM_$reflection())]], [["Item", string_type]], [], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [anonRecord_type(["DataUpdated", bool_type]), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", anonRecord_type(["DataUpdated", bool_type])]], [["ErrorValue", list_type(string_type)]]])]], [["Item", DateRange_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export const notSelectedJobId = "9e944200-9fea-4765-87ba-f166f691cbf8";

export const notSelectedJob = new JobDetail(notSelectedJobId, "Not Selected", minValue(), minValue());

export function getUserData() {
    const matchValue = appRoot.SystemService.Load("UserData");
    if (matchValue.tag === 1) {
        return undefined;
    }
    else {
        return matchValue.fields[0];
    }
}

export function getUserJobOverrides(state) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(10, [Item_1])), () => singleton_1.Delay(() => {
        let asyncResult;
        const value_1_1 = (days_1(op_Subtraction_1(state.DateTo, state.DateFrom)) <= 7) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton("Date range must be less than or equal to 7 days.")]));
        asyncResult = singleton_1.Return(value_1_1);
        return singleton_1.Bind(asyncResult, (input_1_1) => {
            const input_6 = input_1_1;
            if (input_6.tag === 1) {
                return singleton_1.Return(new FSharpResult$2(1, [input_6.fields[0]]));
            }
            else {
                const x_2 = getUserJobOverrides_1(appRoot.JobService, AppRootModule_getSelectedOrgId(), state.DateFrom, state.DateTo, state.NextPagination, state.UserRole);
                return singleton_1.Bind(x_2, (x$0027) => {
                    let _arg_2, jobs;
                    let value_3;
                    const input_2 = x$0027;
                    value_3 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(_arg_2 = input_2.fields[0], (jobs = _arg_2[0], [sortBy((x) => x.Title, jobs, {
                        Compare: comparePrimitives,
                    }), map((dto) => UserJobOverridesVMModule_ofUserJobOverrides(jobs, dto), _arg_2[1])]))])));
                    return singleton_1.Return(value_3);
                });
            }
        });
    }));
}

export function saveUserJobOverridesCmd(state) {
    return cmdOfAsync((Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(10, [Item_1])), () => singleton_1.Delay(() => {
        const userJobOverridesDeletedWithoutSaving = filter((x_1) => {
            if (x_1.Id === "00000000-0000-0000-0000-000000000000") {
                return x_1.IsDeleted;
            }
            else {
                return false;
            }
        }, collect((x) => x.UserJobOverrides, state.UserJobOverridesToUpsert));
        const userJobOverrides = map(UserJobOverrideVMModule_toUserJobOverride, filter((x_3) => {
            if (x_3.IsModified) {
                return !exists((y) => (y.TempId === x_3.TempId), userJobOverridesDeletedWithoutSaving);
            }
            else {
                return false;
            }
        }, collect((x_2) => x_2.UserJobOverrides, state.UserJobOverridesToUpsert)));
        const userOverridesToDelete = map(UserJobOverrideVMModule_toUserJobOverride, filter((x_5) => {
            if (x_5.Id !== "00000000-0000-0000-0000-000000000000") {
                return x_5.IsDeleted;
            }
            else {
                return false;
            }
        }, collect((x_4) => x_4.UserJobOverrides, state.UserJobOverridesToUpsert)));
        if (isEmpty(userJobOverrides) && isEmpty(userOverridesToDelete)) {
            const value_1_1 = new FSharpResult$2(0, [{
                DataUpdated: false,
            }]);
            return singleton_1.Return(value_1_1);
        }
        else {
            const x_6 = saveUserJobOverrides(appRoot.JobService, AppRootModule_getSelectedOrgId(), userJobOverrides, userOverridesToDelete);
            return singleton_1.Bind(x_6, (x$0027) => {
                let value_3;
                const input_2 = x$0027;
                value_3 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [{
                    DataUpdated: true,
                }])));
                return singleton_1.Return(value_3);
            });
        }
    }));
}

function changePage(state, f) {
    const newPagination = f(state.CurrentPagination);
    if (equals_1(state.NextPagination, newPagination)) {
        return [state, Cmd_none()];
    }
    else {
        const updatedState = new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, newPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(1, []), state.Errors);
        return [updatedState, getUserJobOverrides(updatedState)];
    }
}

export function init() {
    const pagination = getDefaultPagination();
    const userDataDetails = LocalUserDataModule_getUserDetails(getUserData());
    const now = date_1(now_1());
    return [new State(singleton(notSelectedJob), empty(), fromDate(now), fromDate(addDays(now, 6)), notSelectedJob.Id, pagination, pagination, false, userDataDetails.UserRole, new Busy(0, []), empty()), Cmd_none()];
}

export function update(msg, state) {
    let input_1;
    switch (msg.tag) {
        case 0:
            return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(1, []), empty()), getUserJobOverrides(state)];
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const updatedState = new State(cons(notSelectedJob, msg.fields[0].fields[0][0]), state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(0, []), state.Errors);
                return [isEmpty(msg.fields[0].fields[0][1]) ? (new State(updatedState.Jobs, updatedState.UserJobOverridesToUpsert, updatedState.DateFrom, updatedState.DateTo, updatedState.SelectedJobId, state.CurrentPagination, updatedState.CurrentPagination, updatedState.IsPaginationDisabled, updatedState.UserRole, updatedState.IsBusy, updatedState.Errors)) : (new State(updatedState.Jobs, msg.fields[0].fields[0][1], updatedState.DateFrom, updatedState.DateTo, updatedState.SelectedJobId, updatedState.NextPagination, state.NextPagination, updatedState.IsPaginationDisabled, updatedState.UserRole, updatedState.IsBusy, updatedState.Errors)), Cmd_none()];
            }
        case 2:
            if (state.SelectedJobId === notSelectedJob.Id) {
                return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, state.IsBusy, singleton("Please select a job to allocate to user.")), Cmd_none()];
            }
            else {
                const userId = item(0, msg.fields[0]).UserId;
                let userJobOverridesForADate;
                if (exists((x) => (x.JobId === state.SelectedJobId), msg.fields[0])) {
                    userJobOverridesForADate = map((y) => {
                        if (y.JobId === state.SelectedJobId) {
                            if (y.IsDeleted) {
                                return new UserJobOverrideVM(y.Id, y.TempId, y.OrgId, y.DateTime, y.JobId, y.JobTitle, y.UserId, y.Username, true, false, y.LeaveRequestType);
                            }
                            else {
                                return new UserJobOverrideVM(y.Id, y.TempId, y.OrgId, y.DateTime, y.JobId, y.JobTitle, y.UserId, y.Username, y.IsModified, true, y.LeaveRequestType);
                            }
                        }
                        else {
                            return y;
                        }
                    }, msg.fields[0]);
                }
                else {
                    const firstOverride = item(0, msg.fields[0]);
                    userJobOverridesForADate = cons(new UserJobOverrideVM("00000000-0000-0000-0000-000000000000", newGuid(), firstOverride.OrgId, firstOverride.DateTime, state.SelectedJobId, defaultArg((input_1 = tryFind((z) => (z.Id === state.SelectedJobId), state.Jobs), (input_1 == null) ? undefined : input_1.Title), ""), firstOverride.UserId, firstOverride.Username, true, false, firstOverride.LeaveRequestType), msg.fields[0]);
                }
                return [new State(state.Jobs, map((x_1) => {
                    if (x_1.UserId === userId) {
                        return new UserJobOverridesVM(x_1.UserId, x_1.Username, x_1.UserIsActive, concat(map((tupledArg) => {
                            if (equals(tupledArg[0], item(0, userJobOverridesForADate).DateTime)) {
                                return userJobOverridesForADate;
                            }
                            else {
                                return tupledArg[1];
                            }
                        }, List_groupBy((y_1) => y_1.DateTime, x_1.UserJobOverrides, {
                            Equals: equals,
                            GetHashCode: dateHash,
                        }))));
                    }
                    else {
                        return x_1;
                    }
                }, state.UserJobOverridesToUpsert), state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, true, state.UserRole, state.IsBusy, empty()), Cmd_none()];
            }
        case 3:
            return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, parse(msg.fields[0]), state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, state.IsBusy, empty()), Cmd_none()];
        case 7:
            return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(2, []), state.Errors), saveUserJobOverridesCmd(state)];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, false, state.UserRole, new Busy(0, []), state.Errors), msg.fields[0].fields[0].DataUpdated ? getUserJobOverrides(state) : Cmd_none()];
            }
        case 4:
            return changePage(state, previousPage);
        case 5:
            return changePage(state, nextPage);
        case 6:
            return changePage(state, firstPage);
        case 10:
            return [new State(state.Jobs, state.UserJobOverridesToUpsert, state.DateFrom, state.DateTo, state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, new Busy(0, []), singleton(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.Jobs, state.UserJobOverridesToUpsert, fromDate(date_2(msg.fields[0].startDate)), fromDate(date_2(msg.fields[0].endDate)), state.SelectedJobId, state.NextPagination, state.CurrentPagination, state.IsPaginationDisabled, state.UserRole, state.IsBusy, state.Errors), Cmd_none()];
    }
}

function weekCalendar(selectedJob, startDate, finishDate, userJobOverrides, dispatch) {
    let daysToAdd, startDate_1, elems_5, elems_4, children_2, children, elems_3;
    const selectedJobStartDate = toLocalTime(selectedJob.StartDate);
    const selectedJobFinishDate = toLocalTime(selectedJob.FinishDate);
    const weekDays = map(DateTime_dateToStringWithDayNameNoYear, (daysToAdd = (~~totalDays(op_Subtraction(date_1(finishDate), date_1(startDate))) | 0), (startDate_1 = date_1(startDate), map((x_1) => addDays(startDate_1, x_1), toList(rangeDouble(0, 1, daysToAdd))))));
    return createElement("div", createObj(singleton((elems_5 = [createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-zebra"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full", "table-fixed"])], (elems_4 = [(children_2 = singleton((children = toList(delay(() => append(singleton_2(createElement("th", createObj(ofArray([alignCellTextTop, ["children", "User"]])))), delay(() => map_1((weekDay) => createElement("th", createObj(ofArray([alignCellTextTop, ["children", weekDay]]))), weekDays))))), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("tbody", createObj(singleton((elems_3 = toList(delay(() => map_1((userJobOverride) => {
        let elems_2;
        return createElement("tr", createObj(ofArray([["className", "border-2 border-gray-200"], (elems_2 = toList(delay(() => append(singleton_2(createElement("td", createObj(toList(delay(() => (userJobOverride.UserIsActive ? append(singleton_2(["className", "border-2 border-gray-200"]), delay(() => singleton_2(["children", userJobOverride.Username]))) : append(singleton_2(["className", "border-2 border-gray-200 bg-red-200"]), delay(() => singleton_2(["children", `${userJobOverride.Username} (Inactive)`]))))))))), delay(() => {
            let userJobsLength, weekDaysLength;
            return collect_1((matchValue) => {
                const weekDate = matchValue[0];
                const userJobOverrides_1 = matchValue[1];
                const isLeaveRequest = exists((x_4) => (x_4.LeaveRequestType != null), userJobOverrides_1);
                return singleton_2(createElement("td", createObj(toList(delay(() => (((compare(weekDate, selectedJobStartDate) < 0) ? true : (compare(weekDate, selectedJobFinishDate) > 0)) ? append(singleton_2(["className", join(" ", ["border-2 border-gray-200 bg-red-200"])]), delay(() => append(singleton_2(["title", "Job is inactive, cannot allocate user to job on this date"]), delay(() => singleton_2(["style", {
                    cursor: "not-allowed",
                }]))))) : append(isLeaveRequest ? singleton_2(["className", join(" ", ["bg-red-200 border-2 border-gray-200"])]) : singleton_2(["className", join(" ", ["border-2 border-gray-200"])]), delay(() => {
                    let matchValue_1, jobTitles, elems_1, elems;
                    return append((matchValue_1 = item(0, userJobOverrides_1).LeaveRequestType, (matchValue_1 == null) ? ((jobTitles = choose((x_8) => {
                        if (x_8.IsDeleted ? true : isNullOrEmpty(x_8.JobTitle)) {
                            return undefined;
                        }
                        else {
                            return x_8.JobTitle;
                        }
                    }, sortBy((x_6) => x_6.JobTitle, userJobOverrides_1, {
                        Compare: comparePrimitives,
                    })), isEmpty(jobTitles) ? singleton_2(["children", ""]) : singleton_2((elems_1 = [createElement("ol", createObj(ofArray([["className", join(" ", ["list-decimal", "list-inside"])], (elems = toList(delay(() => map_1((jobTitle) => createElement("li", {
                        children: jobTitle,
                    }), jobTitles))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))) : singleton_2(["children", LeaveRequestTypeModule_toString(matchValue_1)])), delay(() => append(exists((x_9) => x_9.IsModified, userJobOverrides_1) ? singleton_2(["style", {
                        color: "green",
                    }]) : empty_1(), delay(() => singleton_2(["onClick", (_arg_1) => {
                        if (item(0, userJobOverrides_1).LeaveRequestType == null) {
                            dispatch(new Msg(2, [userJobOverrides_1]));
                        }
                    }])))));
                }))))))));
            }, take((userJobsLength = (length(userJobOverride.UserJobOverrides) | 0), (weekDaysLength = (length(weekDays) | 0), (userJobsLength < weekDaysLength) ? userJobsLength : weekDaysLength)), List_groupBy((x_2) => date_2(toLocalTime(x_2.DateTime)), userJobOverride.UserJobOverrides, {
                Equals: equals,
                GetHashCode: dateHash,
            })));
        })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, userJobOverrides))), ["children", reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_5))]))));
}

export function IndexView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => {
        let children;
        return append(singleton_2((children = singleton(Daisy_h4("User Job Overrides")), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => append(singleton_2(Daisy_info(singleton("Select a job to allocate, then click on the date cell in the table \n                below to assign the job to the user for that day."))), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_2;
            return append(singleton_2((children_2 = singleton(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => {
                let children_9, children_5, elems_2, props_5;
                return append(singleton_2((children_9 = ofArray([(children_5 = singleton(createElement("span", {
                    className: "label-text",
                    children: "Select date range to get user job overrides",
                })), createElement("label", {
                    className: "label",
                    children: reactApi.Children.toArray(Array.from(children_5)),
                })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_2 = [(props_5 = {
                    value: new DateRange(fromDateTimeOffset(state_1.DateFrom, 0), fromDateTimeOffset(state_1.DateTo, 0)),
                    onChange: (x) => {
                        dispatch(new Msg(9, [new DateRange(parse_1(x.startDate), parse_1(x.endDate))]));
                    },
                    showShortcuts: true,
                    configs: getDefaultConfig(now_2()),
                }, react.createElement(react_tailwindcss_datepicker, props_5)), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_2) => {
                    dispatch(new Msg(0, []));
                }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_9)),
                }))), delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => {
                    let children_16, children_13, elems_5;
                    return append(singleton_2((children_16 = ofArray([(children_13 = singleton(createElement("span", {
                        className: "label-text",
                        children: "Select Job to allocate to users",
                    })), createElement("label", {
                        className: "label",
                        children: reactApi.Children.toArray(Array.from(children_13)),
                    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Job"], ["value", state_1.SelectedJobId], ["onChange", (ev) => {
                        dispatch(new Msg(3, [ev.target.value]));
                    }], (elems_5 = toList(delay(() => map_1((job) => createElement("option", {
                        value: job.Id,
                        children: job.Title,
                    }), state_1.Jobs))), ["children", reactApi.Children.toArray(Array.from(elems_5))])]))))]), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_16)),
                    }))), delay(() => append(singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
                        dispatch(new Msg(7, []));
                    }]))))))))))))))), delay(() => append(singleton_2(Daisy_h7("When jobs are overridden for users, they will be displayed in the list of \n            assigned jobs on the mobile app. If no job is selected for a specific day, the \n            user will see all the jobs allocated to them.")), delay(() => append(singleton_2(weekCalendar(defaultArg(tryFind((x_1) => (x_1.Id === state_1.SelectedJobId), state_1.Jobs), notSelectedJob), state_1.DateFrom, state_1.DateTo, state_1.UserJobOverridesToUpsert, dispatch)), delay(() => singleton_2(paginationControl(state_1.IsPaginationDisabled, state_1.CurrentPagination.CurrentPage, (_arg_4) => {
                        dispatch(new Msg(4, []));
                    }, (_arg_5) => {
                        dispatch(new Msg(5, []));
                    }, (_arg_6) => {
                        dispatch(new Msg(6, []));
                    }))))))))));
                }))));
            }));
        }))))));
    })));
}

