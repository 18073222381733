import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, class_type, getUnionCases, list_type, enum_type, union_type, int32_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { contains, singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { printf, toFail } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { addMonths, dayOfWeek, addDays, offset, day as day_1, month, year, create } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { seconds, minutes, hours } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { daysInMonth, compare } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Date.js";
import { defaultArg, value as value_1 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { numberHash } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { min } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Double.js";

export class DayOfMonth extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DayOfMonth", "LastDayOfMonth"];
    }
}

export function DayOfMonth_$reflection() {
    return union_type("Domain.Entity.Schedule.DayOfMonth", [], DayOfMonth, () => [[["Item", int32_type]], []]);
}

export class RepeatPattern extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Daily", "Weekly", "Monthly"];
    }
}

export function RepeatPattern_$reflection() {
    return union_type("Domain.Entity.Schedule.RepeatPattern", [], RepeatPattern, () => [[], [["Item", list_type(enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]]))]], [["Item", DayOfMonth_$reflection()]]]);
}

export const RepeatPatternModule_repeatPatterns = getUnionCases(RepeatPattern_$reflection());

export function RepeatPatternModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Weekly";
        case 2:
            return "Monthly";
        default:
            return "Daily";
    }
}

export function RepeatPatternModule_ofString(s) {
    switch (s) {
        case "Daily":
            return new RepeatPattern(0, []);
        case "Weekly":
            return new RepeatPattern(1, [singleton(1)]);
        case "Monthly":
            return new RepeatPattern(2, [new DayOfMonth(0, [1])]);
        default:
            return toFail(printf("Invalid RepeatPattern: %s"))(s);
    }
}

export class Schedule extends Record {
    constructor(ScheduleTime, RepeatPattern, StartDate, FinishDate) {
        super();
        this.ScheduleTime = ScheduleTime;
        this.RepeatPattern = RepeatPattern;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
    }
}

export function Schedule_$reflection() {
    return record_type("Domain.Entity.Schedule.Schedule", [], Schedule, () => [["ScheduleTime", class_type("System.TimeOnly")], ["RepeatPattern", RepeatPattern_$reflection()], ["StartDate", class_type("System.DateTimeOffset")], ["FinishDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export function getNextScheduleDate(schedule, lastScheduleHandledTime, utcNow) {
    const nextDateTime = (date, time) => create(year(date), month(date), day_1(date), hours(time), minutes(time), seconds(time), offset(date));
    const getNextDaily = (current_mut) => {
        getNextDaily:
        while (true) {
            const current = current_mut;
            const next = addDays(current, 1);
            if ((compare(next, schedule.StartDate) >= 0) && ((schedule.FinishDate == null) ? true : (compare(next, value_1(schedule.FinishDate)) <= 0))) {
                return nextDateTime(next, schedule.ScheduleTime);
            }
            else {
                current_mut = next;
                continue getNextDaily;
            }
            break;
        }
    };
    const getNextWeekly = (current_1_mut, daysOfWeek_mut) => {
        getNextWeekly:
        while (true) {
            const current_1 = current_1_mut, daysOfWeek = daysOfWeek_mut;
            const next_1 = addDays(current_1, 1);
            if ((contains(dayOfWeek(next_1), daysOfWeek, {
                Equals: (x, y) => (x === y),
                GetHashCode: numberHash,
            }) && (compare(next_1, schedule.StartDate) >= 0)) && ((schedule.FinishDate == null) ? true : (compare(next_1, value_1(schedule.FinishDate)) <= 0))) {
                return nextDateTime(next_1, schedule.ScheduleTime);
            }
            else if ((schedule.FinishDate != null) && (compare(next_1, value_1(schedule.FinishDate)) > 0)) {
                return undefined;
            }
            else {
                current_1_mut = next_1;
                daysOfWeek_mut = daysOfWeek;
                continue getNextWeekly;
            }
            break;
        }
    };
    const getNextMonthly = (current_2_mut, dayOfMonth_mut) => {
        getNextMonthly:
        while (true) {
            const current_2 = current_2_mut, dayOfMonth = dayOfMonth_mut;
            const nextMonth = addMonths(current_2, 1);
            const nextDay = ((dayOfMonth.tag === 1) ? daysInMonth(year(nextMonth), month(nextMonth)) : min(dayOfMonth.fields[0], daysInMonth(year(nextMonth), month(nextMonth)))) | 0;
            const next_2 = create(year(nextMonth), month(nextMonth), nextDay, hours(schedule.ScheduleTime), minutes(schedule.ScheduleTime), seconds(schedule.ScheduleTime), offset(nextMonth));
            if ((compare(next_2, schedule.StartDate) >= 0) && ((schedule.FinishDate == null) ? true : (compare(next_2, value_1(schedule.FinishDate)) <= 0))) {
                return next_2;
            }
            else {
                current_2_mut = next_2;
                dayOfMonth_mut = dayOfMonth;
                continue getNextMonthly;
            }
            break;
        }
    };
    const matchValue = schedule.RepeatPattern;
    const matchValue_1 = schedule.FinishDate;
    let matchResult, finishDate_3, finishDate_4, daysOfWeek_1, finishDate_5, dayOfMonth_1;
    switch (matchValue.tag) {
        case 1: {
            if (matchValue_1 != null) {
                if (compare(matchValue_1, utcNow) <= 0) {
                    matchResult = 2;
                    finishDate_4 = matchValue_1;
                }
                else {
                    matchResult = 3;
                    daysOfWeek_1 = matchValue.fields[0];
                }
            }
            else {
                matchResult = 3;
                daysOfWeek_1 = matchValue.fields[0];
            }
            break;
        }
        case 2: {
            if (matchValue_1 != null) {
                if (compare(matchValue_1, utcNow) <= 0) {
                    matchResult = 4;
                    finishDate_5 = matchValue_1;
                }
                else {
                    matchResult = 5;
                    dayOfMonth_1 = matchValue.fields[0];
                }
            }
            else {
                matchResult = 5;
                dayOfMonth_1 = matchValue.fields[0];
            }
            break;
        }
        default:
            if (matchValue_1 != null) {
                if (compare(matchValue_1, utcNow) <= 0) {
                    matchResult = 0;
                    finishDate_3 = matchValue_1;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
    }
    switch (matchResult) {
        case 0:
            return undefined;
        case 1:
            if (lastScheduleHandledTime == null) {
                return getNextDaily(defaultArg(lastScheduleHandledTime, nextDateTime(addDays(utcNow, -1), schedule.ScheduleTime)));
            }
            else {
                return getNextDaily(nextDateTime(lastScheduleHandledTime, schedule.ScheduleTime));
            }
        case 2:
            return undefined;
        case 3:
            if (lastScheduleHandledTime == null) {
                return getNextWeekly(nextDateTime(addDays(utcNow, -1), schedule.ScheduleTime), daysOfWeek_1);
            }
            else {
                return getNextWeekly(nextDateTime(lastScheduleHandledTime, schedule.ScheduleTime), daysOfWeek_1);
            }
        case 4:
            return undefined;
        default:
            if (lastScheduleHandledTime == null) {
                return getNextMonthly(nextDateTime(addMonths(utcNow, -1), schedule.ScheduleTime), dayOfMonth_1);
            }
            else {
                return getNextMonthly(nextDateTime(lastScheduleHandledTime, schedule.ScheduleTime), dayOfMonth_1);
            }
    }
}

