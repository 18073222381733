import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { decimal_type, int64_type, list_type, option_type, string_type, record_type, bool_type, enum_type, int32_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SimproLookup_$reflection } from "./Simpro.fs.js";
import { Address_$reflection } from "./Address.fs.js";
import { Schedule_$reflection } from "./Schedule.fs.js";
import { Location_$reflection } from "./Location.fs.js";
import { LeaveRequest_$reflection } from "./User.fs.js";
import { Status_$reflection } from "./Common.fs.js";

export function InvoiceFrequencyModule_ofString(_arg) {
    switch (_arg) {
        case "Weekly":
            return 0;
        case "Fortnightly":
            return 1;
        case "Monthly":
            return 2;
        case "Yearly":
            return 3;
        default:
            return 0;
    }
}

export function InvoiceFrequencyModule_toString(_arg) {
    switch (_arg) {
        case 1:
            return "Fortnightly";
        case 2:
            return "Monthly";
        case 3:
            return "Yearly";
        default:
            return "Weekly";
    }
}

export class JobShiftSchedule extends Record {
    constructor(Id, JobId, DayOfWeek, ShiftStart, ShiftEnd, IsEnabled) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.DayOfWeek = (DayOfWeek | 0);
        this.ShiftStart = ShiftStart;
        this.ShiftEnd = ShiftEnd;
        this.IsEnabled = IsEnabled;
    }
}

export function JobShiftSchedule_$reflection() {
    return record_type("Domain.Entity.Job.JobShiftSchedule", [], JobShiftSchedule, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["DayOfWeek", enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]])], ["ShiftStart", class_type("System.TimeOnly")], ["ShiftEnd", class_type("System.TimeOnly")], ["IsEnabled", bool_type]]);
}

export class JobShiftScheduleOverride extends Record {
    constructor(Id, JobId, IsEnabled, IsHoliday, DateTime, ShiftStart, ShiftEnd, Reason) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.IsEnabled = IsEnabled;
        this.IsHoliday = IsHoliday;
        this.DateTime = DateTime;
        this.ShiftStart = ShiftStart;
        this.ShiftEnd = ShiftEnd;
        this.Reason = Reason;
    }
}

export function JobShiftScheduleOverride_$reflection() {
    return record_type("Domain.Entity.Job.JobShiftScheduleOverride", [], JobShiftScheduleOverride, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["IsEnabled", bool_type], ["IsHoliday", bool_type], ["DateTime", class_type("System.DateTime")], ["ShiftStart", class_type("System.TimeOnly")], ["ShiftEnd", class_type("System.TimeOnly")], ["Reason", string_type]]);
}

export class SimproJobDetail extends Record {
    constructor(JobId, CompanyId, Description, SectionId, CostCentreId, ExpenseSectionId, ExpenseCostCentreId, Sections, SectionCostCentres, SectionExpenseCostCentres, QaUploadFolderId, JobAttachmentFolders) {
        super();
        this.JobId = (JobId | 0);
        this.CompanyId = (CompanyId | 0);
        this.Description = Description;
        this.SectionId = (SectionId | 0);
        this.CostCentreId = (CostCentreId | 0);
        this.ExpenseSectionId = ExpenseSectionId;
        this.ExpenseCostCentreId = ExpenseCostCentreId;
        this.Sections = Sections;
        this.SectionCostCentres = SectionCostCentres;
        this.SectionExpenseCostCentres = SectionExpenseCostCentres;
        this.QaUploadFolderId = QaUploadFolderId;
        this.JobAttachmentFolders = JobAttachmentFolders;
    }
}

export function SimproJobDetail_$reflection() {
    return record_type("Domain.Entity.Job.SimproJobDetail", [], SimproJobDetail, () => [["JobId", int32_type], ["CompanyId", int32_type], ["Description", string_type], ["SectionId", int32_type], ["CostCentreId", int32_type], ["ExpenseSectionId", option_type(int32_type)], ["ExpenseCostCentreId", option_type(int32_type)], ["Sections", list_type(SimproLookup_$reflection())], ["SectionCostCentres", list_type(SimproLookup_$reflection())], ["SectionExpenseCostCentres", list_type(SimproLookup_$reflection())], ["QaUploadFolderId", option_type(int32_type)], ["JobAttachmentFolders", list_type(SimproLookup_$reflection())]]);
}

export class JobShiftCheckItem extends Record {
    constructor(Id, Name, Description, Priority, Created, Updated) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function JobShiftCheckItem_$reflection() {
    return record_type("Domain.Entity.Job.JobShiftCheckItem", [], JobShiftCheckItem, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Created", class_type("System.DateTimeOffset")], ["Updated", class_type("System.DateTimeOffset")]]);
}

export class JobShiftCheckItemName extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function JobShiftCheckItemName_$reflection() {
    return record_type("Domain.Entity.Job.JobShiftCheckItemName", [], JobShiftCheckItemName, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export const JobShiftCheckItemNameModule_notSelected = new JobShiftCheckItemName("00000000-0000-0000-0000-000000000000", "Not Selected");

export class Job extends Record {
    constructor(Id, CustomerId, SiteManagers, Title, StartDate, EndDate, Address, InvoiceFrequency, InvoiceStartDate, ShiftStartGeoFenceRadius, ShiftStartNotificationEnabled, ShiftStartNotificationMessage, ShiftFinishNotificationEnabled, ShiftFinishNotificationMessage, JobShiftSchedules, IsActive, SimproConnectionDetailId, SimproJobDetail, IsUsingShiftPreCheck, IsUsingShiftPostCheck, JobShiftPreCheckItems, JobShiftPostCheckItems, IsSimproQAUploadScheduleActive, SimproQAUploadSchedule, Created, Modified) {
        super();
        this.Id = Id;
        this.CustomerId = CustomerId;
        this.SiteManagers = SiteManagers;
        this.Title = Title;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Address = Address;
        this.InvoiceFrequency = (InvoiceFrequency | 0);
        this.InvoiceStartDate = InvoiceStartDate;
        this.ShiftStartGeoFenceRadius = (ShiftStartGeoFenceRadius | 0);
        this.ShiftStartNotificationEnabled = ShiftStartNotificationEnabled;
        this.ShiftStartNotificationMessage = ShiftStartNotificationMessage;
        this.ShiftFinishNotificationEnabled = ShiftFinishNotificationEnabled;
        this.ShiftFinishNotificationMessage = ShiftFinishNotificationMessage;
        this.JobShiftSchedules = JobShiftSchedules;
        this.IsActive = IsActive;
        this.SimproConnectionDetailId = SimproConnectionDetailId;
        this.SimproJobDetail = SimproJobDetail;
        this.IsUsingShiftPreCheck = IsUsingShiftPreCheck;
        this.IsUsingShiftPostCheck = IsUsingShiftPostCheck;
        this.JobShiftPreCheckItems = JobShiftPreCheckItems;
        this.JobShiftPostCheckItems = JobShiftPostCheckItems;
        this.IsSimproQAUploadScheduleActive = IsSimproQAUploadScheduleActive;
        this.SimproQAUploadSchedule = SimproQAUploadSchedule;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function Job_$reflection() {
    return record_type("Domain.Entity.Job.Job", [], Job, () => [["Id", class_type("System.Guid")], ["CustomerId", class_type("System.Guid")], ["SiteManagers", list_type(class_type("System.Guid"))], ["Title", string_type], ["StartDate", class_type("System.DateTimeOffset")], ["EndDate", class_type("System.DateTimeOffset")], ["Address", Address_$reflection()], ["InvoiceFrequency", enum_type("Domain.Entity.Job.InvoiceFrequency", int32_type, [["Weekly", 0], ["Fortnightly", 1], ["Monthly", 2], ["Yearly", 3]])], ["InvoiceStartDate", option_type(class_type("System.DateTimeOffset"))], ["ShiftStartGeoFenceRadius", int32_type], ["ShiftStartNotificationEnabled", bool_type], ["ShiftStartNotificationMessage", string_type], ["ShiftFinishNotificationEnabled", bool_type], ["ShiftFinishNotificationMessage", string_type], ["JobShiftSchedules", list_type(JobShiftSchedule_$reflection())], ["IsActive", bool_type], ["SimproConnectionDetailId", option_type(class_type("System.Guid"))], ["SimproJobDetail", option_type(SimproJobDetail_$reflection())], ["IsUsingShiftPreCheck", bool_type], ["IsUsingShiftPostCheck", bool_type], ["JobShiftPreCheckItems", list_type(JobShiftCheckItem_$reflection())], ["JobShiftPostCheckItems", list_type(JobShiftCheckItem_$reflection())], ["IsSimproQAUploadScheduleActive", bool_type], ["SimproQAUploadSchedule", option_type(Schedule_$reflection())], ["Created", class_type("System.DateTime")], ["Modified", option_type(class_type("System.DateTime"))]]);
}

export class JobDetail extends Record {
    constructor(Id, Title, StartDate, FinishDate) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
    }
}

export function JobDetail_$reflection() {
    return record_type("Domain.Entity.Job.JobDetail", [], JobDetail, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["StartDate", class_type("System.DateTimeOffset")], ["FinishDate", class_type("System.DateTimeOffset")]]);
}

export class JobSimproQAUploadDetail extends Record {
    constructor(Id, OrgId, SimproQAUploadSchedule) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.SimproQAUploadSchedule = SimproQAUploadSchedule;
    }
}

export function JobSimproQAUploadDetail_$reflection() {
    return record_type("Domain.Entity.Job.JobSimproQAUploadDetail", [], JobSimproQAUploadDetail, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["SimproQAUploadSchedule", Schedule_$reflection()]]);
}

export class JobDetailForInvoicing extends Record {
    constructor(Id, Title, CustomerId, XeroCustomerId, JobStartDate, InvoiceFrequency, InvoiceStartDate, LastInvoiceDate) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.CustomerId = CustomerId;
        this.XeroCustomerId = XeroCustomerId;
        this.JobStartDate = JobStartDate;
        this.InvoiceFrequency = (InvoiceFrequency | 0);
        this.InvoiceStartDate = InvoiceStartDate;
        this.LastInvoiceDate = LastInvoiceDate;
    }
}

export function JobDetailForInvoicing_$reflection() {
    return record_type("Domain.Entity.Job.JobDetailForInvoicing", [], JobDetailForInvoicing, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["CustomerId", class_type("System.Guid")], ["XeroCustomerId", option_type(class_type("System.Guid"))], ["JobStartDate", class_type("System.DateTimeOffset")], ["InvoiceFrequency", enum_type("Domain.Entity.Job.InvoiceFrequency", int32_type, [["Weekly", 0], ["Fortnightly", 1], ["Monthly", 2], ["Yearly", 3]])], ["InvoiceStartDate", option_type(class_type("System.DateTimeOffset"))], ["LastInvoiceDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export class JobPayrollDetail extends Record {
    constructor(Id, Title, BusinessName) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.BusinessName = BusinessName;
    }
}

export function JobPayrollDetail_$reflection() {
    return record_type("Domain.Entity.Job.JobPayrollDetail", [], JobPayrollDetail, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["BusinessName", string_type]]);
}

export class JobSearchResult extends Record {
    constructor(BusinessName, Job, RequiredUserAllocationCount, AllocatedUserCount) {
        super();
        this.BusinessName = BusinessName;
        this.Job = Job;
        this.RequiredUserAllocationCount = RequiredUserAllocationCount;
        this.AllocatedUserCount = AllocatedUserCount;
    }
}

export function JobSearchResult_$reflection() {
    return record_type("Domain.Entity.Job.JobSearchResult", [], JobSearchResult, () => [["BusinessName", string_type], ["Job", Job_$reflection()], ["RequiredUserAllocationCount", int64_type], ["AllocatedUserCount", int64_type]]);
}

export class JobUserSkill extends Record {
    constructor(Id, JobId, SkillId, UserCount, Rating, EstimatedPrice, AgreedPrice) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.SkillId = SkillId;
        this.UserCount = (UserCount | 0);
        this.Rating = Rating;
        this.EstimatedPrice = EstimatedPrice;
        this.AgreedPrice = AgreedPrice;
    }
}

export function JobUserSkill_$reflection() {
    return record_type("Domain.Entity.Job.JobUserSkill", [], JobUserSkill, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["SkillId", class_type("System.Guid")], ["UserCount", int32_type], ["Rating", option_type(enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]]))], ["EstimatedPrice", decimal_type], ["AgreedPrice", decimal_type]]);
}

export class TimeLog extends Record {
    constructor(Id, GroupId, JobId, UserId, EventType, LogTime, LogLocation, Note) {
        super();
        this.Id = Id;
        this.GroupId = GroupId;
        this.JobId = JobId;
        this.UserId = UserId;
        this.EventType = (EventType | 0);
        this.LogTime = LogTime;
        this.LogLocation = LogLocation;
        this.Note = Note;
    }
}

export function TimeLog_$reflection() {
    return record_type("Domain.Entity.Job.TimeLog", [], TimeLog, () => [["Id", class_type("System.Guid")], ["GroupId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["EventType", enum_type("Domain.Entity.Job.EventType", int32_type, [["Unknown", 0], ["StartWork", 100], ["StartBreak", 200], ["EndBreak", 300], ["EndWork", 400]])], ["LogTime", class_type("System.DateTime")], ["LogLocation", Location_$reflection()], ["Note", option_type(string_type)]]);
}

export class WorkerJobAllocation extends Record {
    constructor(JobName, TotalWorkersAllocated) {
        super();
        this.JobName = JobName;
        this.TotalWorkersAllocated = TotalWorkersAllocated;
    }
}

export function WorkerJobAllocation_$reflection() {
    return record_type("Domain.Entity.Job.WorkerJobAllocation", [], WorkerJobAllocation, () => [["JobName", string_type], ["TotalWorkersAllocated", int64_type]]);
}

export class WorkforceSkill extends Record {
    constructor(Skill, WorkerCount) {
        super();
        this.Skill = Skill;
        this.WorkerCount = WorkerCount;
    }
}

export function WorkforceSkill_$reflection() {
    return record_type("Domain.Entity.Job.WorkforceSkill", [], WorkforceSkill, () => [["Skill", string_type], ["WorkerCount", int64_type]]);
}

export class WorkersAtJob extends Record {
    constructor(JobName, JobLocation, WorkerCount) {
        super();
        this.JobName = JobName;
        this.JobLocation = JobLocation;
        this.WorkerCount = (WorkerCount | 0);
    }
}

export function WorkersAtJob_$reflection() {
    return record_type("Domain.Entity.Job.WorkersAtJob", [], WorkersAtJob, () => [["JobName", string_type], ["JobLocation", Location_$reflection()], ["WorkerCount", int32_type]]);
}

export class WorkerDurationByJob extends Record {
    constructor(JobName, WorkerName, ShiftStart, ShiftFinish, TotalWorkDuration, TotalOvertimeDuration) {
        super();
        this.JobName = JobName;
        this.WorkerName = WorkerName;
        this.ShiftStart = ShiftStart;
        this.ShiftFinish = ShiftFinish;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function WorkerDurationByJob_$reflection() {
    return record_type("Domain.Entity.Job.WorkerDurationByJob", [], WorkerDurationByJob, () => [["JobName", string_type], ["WorkerName", string_type], ["ShiftStart", class_type("System.DateTime")], ["ShiftFinish", class_type("System.DateTime")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export class UserJobOverride extends Record {
    constructor(Id, OrgId, DateTime, JobId, UserId, Username) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.DateTime = DateTime;
        this.JobId = JobId;
        this.UserId = UserId;
        this.Username = Username;
    }
}

export function UserJobOverride_$reflection() {
    return record_type("Domain.Entity.Job.UserJobOverride", [], UserJobOverride, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["DateTime", class_type("System.DateTimeOffset")], ["JobId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type]]);
}

export class UserJobOverrides extends Record {
    constructor(UserId, Username, UserIsActive, UserJobOverrides, UserLeaves) {
        super();
        this.UserId = UserId;
        this.Username = Username;
        this.UserIsActive = UserIsActive;
        this.UserJobOverrides = UserJobOverrides;
        this.UserLeaves = UserLeaves;
    }
}

export function UserJobOverrides_$reflection() {
    return record_type("Domain.Entity.Job.UserJobOverrides", [], UserJobOverrides, () => [["UserId", class_type("System.Guid")], ["Username", string_type], ["UserIsActive", bool_type], ["UserJobOverrides", list_type(UserJobOverride_$reflection())], ["UserLeaves", list_type(LeaveRequest_$reflection())]]);
}

export class JobShiftCheckTemplate extends Record {
    constructor(Id, Name, ShiftCheckItems) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ShiftCheckItems = ShiftCheckItems;
    }
}

export function JobShiftCheckTemplate_$reflection() {
    return record_type("Domain.Entity.Job.JobShiftCheckTemplate", [], JobShiftCheckTemplate, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["ShiftCheckItems", list_type(JobShiftCheckItem_$reflection())]]);
}

export class JobSearchDetails extends Record {
    constructor(SearchText, Status) {
        super();
        this.SearchText = SearchText;
        this.Status = Status;
    }
}

export function JobSearchDetails_$reflection() {
    return record_type("Domain.Entity.Job.JobSearchDetails", [], JobSearchDetails, () => [["SearchText", string_type], ["Status", Status_$reflection()]]);
}

export class WorkerJobDetail extends Record {
    constructor(JobId, Title, Customer, StartDate, EndDate) {
        super();
        this.JobId = JobId;
        this.Title = Title;
        this.Customer = Customer;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
    }
}

export function WorkerJobDetail_$reflection() {
    return record_type("Domain.Entity.Job.WorkerJobDetail", [], WorkerJobDetail, () => [["JobId", class_type("System.Guid")], ["Title", string_type], ["Customer", string_type], ["StartDate", class_type("System.DateTimeOffset")], ["EndDate", class_type("System.DateTimeOffset")]]);
}

export class WorkerJobs extends Record {
    constructor(WorkerName, Jobs) {
        super();
        this.WorkerName = WorkerName;
        this.Jobs = Jobs;
    }
}

export function WorkerJobs_$reflection() {
    return record_type("Domain.Entity.Job.WorkerJobs", [], WorkerJobs, () => [["WorkerName", string_type], ["Jobs", list_type(WorkerJobDetail_$reflection())]]);
}

