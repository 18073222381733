import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, string_type, option_type, list_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SimproContractorWorkOrderDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Simpro.fs.js";
import { Pagination_$reflection } from "../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getSimproContractorWorkOrderDetails } from "../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { paginationControl, firstPage, nextPage, previousPage, getDefaultPagination } from "../../Component/Pagination.fs.js";
import { ofArray, isEmpty, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { UserRole } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { map, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_DialogWidth, Daisy_progressState, Daisy_error } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { showModalDialog } from "../../Component/ModalDialog.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { format, join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTime_dateToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { System_Decimal__Decimal_ToDisplayCurrency } from "../../../../Logos.Shared/Util.fs.js";

export class State extends Record {
    constructor(JobId, SimproContractorWorkOrderDetails, NextPagination, CurrentPagination, PayrollRunToShow, Errors, IsBusy) {
        super();
        this.JobId = JobId;
        this.SimproContractorWorkOrderDetails = SimproContractorWorkOrderDetails;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.PayrollRunToShow = PayrollRunToShow;
        this.Errors = Errors;
        this.IsBusy = IsBusy;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.SimproContractorWorkOrder.State", [], State, () => [["JobId", class_type("System.Guid")], ["SimproContractorWorkOrderDetails", list_type(SimproContractorWorkOrderDetail_$reflection())], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["PayrollRunToShow", option_type(class_type("System.Guid"))], ["Errors", list_type(string_type)], ["IsBusy", Busy_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetSimproContractorWorkOrderDetailsResponse", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "ShowPayrollRunEdit", "HidePayrollRunEdit", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.SimproContractorWorkOrder.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SimproContractorWorkOrderDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(SimproContractorWorkOrderDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [], [], [["Item", class_type("System.Guid")]], [], [["Item", class_type("System.Exception")]]]);
}

export function getSimproContractorWorkOrderDetailsCmd(jobId, pagination) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getSimproContractorWorkOrderDetails(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId, pagination)));
}

function changePage(state, f) {
    const newPagination = f(state.CurrentPagination);
    if (equals(state.NextPagination, newPagination)) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(state.JobId, state.SimproContractorWorkOrderDetails, newPagination, state.CurrentPagination, state.PayrollRunToShow, state.Errors, new Busy(1, [])), getSimproContractorWorkOrderDetailsCmd(state.JobId, newPagination)];
    }
}

export function init(jobId) {
    const pagination = getDefaultPagination();
    return [new State(jobId, empty(), pagination, pagination, undefined, empty(), new Busy(1, [])), getSimproContractorWorkOrderDetailsCmd(jobId, pagination)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return changePage(state, previousPage);
        case 2:
            return changePage(state, nextPage);
        case 3:
            return changePage(state, firstPage);
        case 4:
            return [new State(state.JobId, state.SimproContractorWorkOrderDetails, state.NextPagination, state.CurrentPagination, msg.fields[0], state.Errors, state.IsBusy), Cmd_none()];
        case 5:
            return [new State(state.JobId, state.SimproContractorWorkOrderDetails, state.NextPagination, state.CurrentPagination, undefined, state.Errors, state.IsBusy), Cmd_none()];
        case 6:
            return [new State(state.JobId, state.SimproContractorWorkOrderDetails, state.NextPagination, state.CurrentPagination, state.PayrollRunToShow, singleton_1(msg.fields[0].message), new Busy(0, [])), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.SimproContractorWorkOrderDetails, state.NextPagination, state.CurrentPagination, state.PayrollRunToShow, msg.fields[0].fields[0], new Busy(0, [])), Cmd_none()];
            }
            else {
                return [isEmpty(msg.fields[0].fields[0]) ? (new State(state.JobId, state.SimproContractorWorkOrderDetails, state.CurrentPagination, state.CurrentPagination, state.PayrollRunToShow, state.Errors, new Busy(0, []))) : (new State(state.JobId, msg.fields[0].fields[0], state.NextPagination, state.CurrentPagination, state.PayrollRunToShow, state.Errors, new Busy(0, []))), Cmd_none()];
            }
    }
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.JobId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const hideViewPaymentRunButton = toString(props.UserRole) === toString(new UserRole(1, []));
    const children_12 = toList(delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
        let children;
        return append(singleton_2((children = singleton_1(createElement(Daisy_progressState, {
            busy: state_1.IsBusy,
        })), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let matchValue, x;
            return append((matchValue = state_1.PayrollRunToShow, (matchValue == null) ? singleton_2(react.createElement(react.Fragment, {})) : ((x = matchValue, singleton_2(createElement(showModalDialog, {
                dialogId: x,
                header: "",
                width: new Daisy_DialogWidth(4, []),
                heightPercent: "90",
                body: () => props.AppPageService.TimesheetPaymentRunEdit(x),
                onShow: () => {
                },
                onClose: () => {
                    dispatch(new Msg(5, []));
                },
            }))))), delay(() => {
                let elems_5, children_5, children_3, children_8;
                return append(singleton_2(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_5 = [(children_5 = singleton_1((children_3 = toList(delay(() => append(props.ShowJobTitle ? singleton_2(createElement("th", {
                    width: 20 + "%",
                    children: "Job",
                })) : empty_1(), delay(() => append(singleton_2(createElement("td", {
                    width: 20 + "%",
                    children: "Date",
                })), delay(() => append(singleton_2(createElement("th", {
                    className: "text-right",
                    width: 20 + "%",
                    children: "Total Hours",
                })), delay(() => append(singleton_2(createElement("th", {
                    className: "text-right",
                    width: 20 + "%",
                    children: "Total Amount",
                })), delay(() => singleton_2(createElement("th", {
                    width: 20 + "%",
                    children: "Payment Run Number",
                })))))))))))), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_5)),
                })), (children_8 = toList(delay(() => map((workOrder) => {
                    let elems_4;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_4 = toList(delay(() => append(props.ShowJobTitle ? singleton_2(createElement("td", {
                        children: workOrder.JobTitle,
                    })) : empty_1(), delay(() => append(singleton_2(createElement("td", {
                        children: DateTime_dateToStringWithDayName(toLocalTime(workOrder.Date)),
                    })), delay(() => append(singleton_2(createElement("td", {
                        className: "text-right",
                        children: format('{0:' + "#,##0.00" + '}', workOrder.LabourHours),
                    })), delay(() => append(singleton_2(createElement("td", {
                        className: "text-right",
                        children: System_Decimal__Decimal_ToDisplayCurrency(workOrder.LabourCost),
                    })), delay(() => {
                        let elems_3;
                        return singleton_2(createElement("td", createObj(ofArray([["className", "grid grid-cols-2 gap-2"], (elems_3 = toList(delay(() => append(singleton_2(createElement("div", {
                            children: workOrder.PaymentRunNumber,
                        })), delay(() => {
                            let elems_2, elems_1;
                            return !hideViewPaymentRunButton ? singleton_2(createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "View Payroll Run"], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems_1 = [createElement("i", {
                                className: "fa fa-book",
                            })], ["children", reactApi.Children.toArray(Array.from(elems_1))]), ["onClick", (_arg_5) => {
                                dispatch(new Msg(4, [workOrder.PaymentRunId]));
                            }]])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))) : empty_1();
                        })))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
                    })))))))))), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
                }, state_1.SimproContractorWorkOrderDetails))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_8)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))))), delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => singleton_2(paginationControl(false, state_1.CurrentPagination.CurrentPage, (_arg_6) => {
                    dispatch(new Msg(1, []));
                }, (_arg_7) => {
                    dispatch(new Msg(2, []));
                }, (_arg_8) => {
                    dispatch(new Msg(3, []));
                }))))));
            }));
        }));
    }))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    });
}

