import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, string_type, decimal_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { op_Subtraction, toNumber } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Decimal.js";

export class Location extends Record {
    constructor(Latitude, Longitude, Address) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.Address = Address;
    }
}

export function Location_$reflection() {
    return record_type("Domain.Entity.Location.Location", [], Location, () => [["Latitude", decimal_type], ["Longitude", decimal_type], ["Address", option_type(string_type)]]);
}

export function LocationModule_getDistanceInMeters(startLocation, finishLocation) {
    try {
        const radians = (degrees) => ((degrees * 3.141592653589793) / 180);
        const dLat = radians(toNumber(op_Subtraction(startLocation.Latitude, finishLocation.Latitude)));
        const dLon = radians(toNumber(op_Subtraction(startLocation.Longitude, finishLocation.Longitude)));
        const a = (Math.sin(dLat / 2) * Math.sin(dLat / 2)) + (((Math.cos(radians(toNumber(finishLocation.Latitude))) * Math.cos(radians(toNumber(startLocation.Latitude)))) * Math.sin(dLon / 2)) * Math.sin(dLon / 2));
        return new FSharpResult$2(0, [6371000 * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)))]);
    }
    catch (e) {
        return new FSharpResult$2(1, [e.message]);
    }
}

