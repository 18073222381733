import { createElement } from "react";
import React from "react";
import { ReportCard, IndexView as IndexView_1 } from "../BaseReport.fs.js";
import { IndexView as IndexView_2 } from "./Report/UserListReport.fs.js";
import { IndexView as IndexView_3 } from "./Report/TimesheetReport.fs.js";
import { IndexView as IndexView_4 } from "./Report/TimesheetReportByWorker.fs.js";
import { IndexView as IndexView_5 } from "./Report/TimesheetsReportByJobs.fs.js";
import { IndexView as IndexView_6 } from "./Report/TimesheetReportForUser.fs.js";
import { IndexView as IndexView_7 } from "./Report/WorkerDurationByJob.fs.js";
import { IndexView as IndexView_8 } from "./Report/ShiftUsers.fs.js";
import { IndexView as IndexView_9 } from "./Report/WorkerJobs.fs.js";
import { ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";

export function IndexView() {
    return createElement(IndexView_1, {
        reportCards: ofArray([new ReportCard("User Report", "List of users in the system, user related search criteria can be applied.", createElement(IndexView_2, null)), new ReportCard("Job duration summary report", "Summary of job durations from timesheets for a given date range.", createElement(IndexView_3, null)), new ReportCard("Job duration by workers report", "Summary of job durations from timesheets for a given date range for workers.", createElement(IndexView_4, null)), new ReportCard("Timesheets by Jobs report", "Summary of timesheets by jobs for a given date range.", createElement(IndexView_5, null)), new ReportCard("User Timesheets for a duration report", "User Timesheets for a given date range.", createElement(IndexView_6, null)), new ReportCard("Worker shift duration by Job report", "Summary of worker shift durations from timesheets for a given date range.", createElement(IndexView_7, null)), new ReportCard("Users on shift report", "List of users on shift for a given date range.", createElement(IndexView_8, null)), new ReportCard("Worker Jobs report", "Workers assigned to jobs, including any job-specific overrides.", createElement(IndexView_9, null))]),
        reportPageTitle: "WorkMate Reports",
    });
}

