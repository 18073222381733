import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { name as name_74, union_type, tuple_type, enum_type, int32_type, record_type, list_type, lambda_type, unit_type, anonRecord_type, option_type, string_type, class_type, bool_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { RepeatPatternModule_repeatPatterns, DayOfMonth, RepeatPattern as RepeatPattern_5, RepeatPatternModule_ofString, Schedule, RepeatPatternModule_toString, DayOfMonth_$reflection, Schedule_$reflection, RepeatPattern_$reflection } from "../../../Logos.Shared/Domain/Entity/Schedule.fs.js";
import { contains, ofArray, singleton, filter, cons, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { parse, toString, create } from "../../fable_modules/fable-library-js.4.19.3/TimeOnly.js";
import { month, year, parse as parse_1, now } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { TitleSize, viewBox } from "./ViewBox.fs.js";
import { int32ToString, numberHash, createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { collect, map, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { daysInMonth, toString as toString_1 } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { DayOfWeek_toString } from "../../AppDomain/Util.fs.js";
import { parse as parse_2 } from "../../fable_modules/fable-library-js.4.19.3/Int32.js";
import { rangeDouble } from "../../fable_modules/fable-library-js.4.19.3/Range.js";

export class State extends Record {
    constructor(IsScheduleActive, ScheduleTime, RepeatPatternName, RepeatPattern, StartDate, FinishDate, OnScheduleChange, Errors) {
        super();
        this.IsScheduleActive = IsScheduleActive;
        this.ScheduleTime = ScheduleTime;
        this.RepeatPatternName = RepeatPatternName;
        this.RepeatPattern = RepeatPattern;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.OnScheduleChange = OnScheduleChange;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("ScheduleInput.State", [], State, () => [["IsScheduleActive", bool_type], ["ScheduleTime", class_type("System.TimeOnly")], ["RepeatPatternName", string_type], ["RepeatPattern", RepeatPattern_$reflection()], ["StartDate", class_type("System.DateTimeOffset")], ["FinishDate", option_type(class_type("System.DateTimeOffset"))], ["OnScheduleChange", lambda_type(anonRecord_type(["IsScheduleActive", bool_type], ["Schedule", Schedule_$reflection()]), unit_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SteIsScheduleActive", "SetScheduleTime", "SetRepeatPatternName", "SetWeeklyDay", "SetMonthlyDay", "SetMonthlyLastDay", "SetStartDate", "SetFinishDate", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("ScheduleInput.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", class_type("System.TimeOnly")]], [["Item", string_type]], [["Item", tuple_type(enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]]), bool_type)]], [["Item", DayOfMonth_$reflection()]], [], [["Item", class_type("System.DateTimeOffset")]], [["Item", option_type(class_type("System.DateTimeOffset"))]], [["Item", class_type("System.Exception")]]]);
}

export function init(isScheduleActive, schedule, onScheduleChange) {
    return [new State(isScheduleActive, schedule.ScheduleTime, RepeatPatternModule_toString(schedule.RepeatPattern), schedule.RepeatPattern, schedule.StartDate, schedule.FinishDate, onScheduleChange, empty()), Cmd_none()];
}

export function update(msg, state) {
    const fireOnScheduleChange = (state_1) => {
        state_1.OnScheduleChange({
            IsScheduleActive: state_1.IsScheduleActive,
            Schedule: new Schedule(state_1.ScheduleTime, state_1.RepeatPattern, state_1.StartDate, state_1.FinishDate),
        });
    };
    switch (msg.tag) {
        case 1: {
            const updatedState_1 = new State(state.IsScheduleActive, msg.fields[0], state.RepeatPatternName, state.RepeatPattern, state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors);
            fireOnScheduleChange(updatedState_1);
            return [updatedState_1, Cmd_none()];
        }
        case 2: {
            const x_2 = msg.fields[0];
            const updatedState_2 = new State(state.IsScheduleActive, state.ScheduleTime, x_2, RepeatPatternModule_ofString(x_2), state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors);
            fireOnScheduleChange(updatedState_2);
            return [updatedState_2, Cmd_none()];
        }
        case 6: {
            const updatedState_3 = new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, state.RepeatPattern, msg.fields[0], state.FinishDate, state.OnScheduleChange, state.Errors);
            fireOnScheduleChange(updatedState_3);
            return [updatedState_3, Cmd_none()];
        }
        case 7: {
            const updatedState_4 = new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, state.RepeatPattern, state.StartDate, msg.fields[0], state.OnScheduleChange, state.Errors);
            fireOnScheduleChange(updatedState_4);
            return [updatedState_4, Cmd_none()];
        }
        case 3: {
            const dayOfWeek = msg.fields[0][0] | 0;
            const matchValue = state.RepeatPattern;
            if (matchValue.tag === 1) {
                const daysOfWeek = matchValue.fields[0];
                const updatedState_5 = msg.fields[0][1] ? (new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, new RepeatPattern_5(1, [cons(dayOfWeek, daysOfWeek)]), state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors)) : (new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, new RepeatPattern_5(1, [filter((x_5) => (x_5 !== dayOfWeek), daysOfWeek)]), state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors));
                fireOnScheduleChange(updatedState_5);
                return [updatedState_5, Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4:
            if (state.RepeatPattern.tag === 2) {
                const updatedState_6 = new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, new RepeatPattern_5(2, [msg.fields[0]]), state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors);
                fireOnScheduleChange(updatedState_6);
                return [updatedState_6, Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 5:
            if (state.RepeatPattern.tag === 2) {
                const updatedState_7 = new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, new RepeatPattern_5(2, [new DayOfMonth(1, [])]), state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors);
                fireOnScheduleChange(updatedState_7);
                return [updatedState_7, Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 8:
            return [new State(state.IsScheduleActive, state.ScheduleTime, state.RepeatPatternName, state.RepeatPattern, state.StartDate, state.FinishDate, state.OnScheduleChange, singleton(msg.fields[0].message)), Cmd_none()];
        default: {
            const updatedState = new State(msg.fields[0], state.ScheduleTime, state.RepeatPatternName, state.RepeatPattern, state.StartDate, state.FinishDate, state.OnScheduleChange, state.Errors);
            fireOnScheduleChange(updatedState);
            return [updatedState, Cmd_none()];
        }
    }
}

export function IndexView(props) {
    let elems_16;
    let patternInput;
    const init_1 = init(props.IsScheduleActive, defaultArg(props.Schedule, new Schedule(create(5, 0), new RepeatPattern_5(0, []), now(), undefined)), props.OnScheduleChange);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return viewBox(props.Title, new TitleSize(0, []), singleton(createElement("div", createObj(singleton((elems_16 = toList(delay(() => {
        let elems_15;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-4"])], (elems_15 = toList(delay(() => {
            let children_1;
            return append(singleton_1((children_1 = ofArray([createElement("span", createObj(Helpers_combineClasses("label-text", singleton(["children", "Is Schedule Active"])))), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["checked", state_1.IsScheduleActive], ["onChange", (ev) => {
                dispatch(new Msg(0, [ev.target.checked]));
            }]]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_1)),
            }))), delay(() => append(singleton_1(createElement("div", {})), delay(() => {
                let children_4;
                return append(singleton_1((children_4 = ofArray([createElement("span", createObj(Helpers_combineClasses("label-text", singleton(["children", "Schedule Time"])))), createElement("input", {
                    type: "time",
                    className: "input input-bordered input-sm",
                    value: toString(state_1.ScheduleTime),
                    disabled: !state_1.IsScheduleActive,
                    onInput: (e_1) => {
                        dispatch(new Msg(1, [parse(e_1.target.value)]));
                    },
                })]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_4)),
                }))), delay(() => append(singleton_1(createElement("div", {})), delay(() => {
                    let children_7;
                    return append(singleton_1((children_7 = ofArray([createElement("span", createObj(Helpers_combineClasses("label-text", singleton(["children", "Start Date"])))), createElement("input", {
                        type: "date",
                        className: "input input-bordered input-sm",
                        value: toString_1(state_1.StartDate, "yyyy-MM-dd"),
                        disabled: !state_1.IsScheduleActive,
                        onInput: (e_2) => {
                            dispatch(new Msg(6, [parse_1(e_2.target.value)]));
                        },
                    })]), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_7)),
                    }))), delay(() => {
                        let children_10, input_1;
                        return append(singleton_1((children_10 = ofArray([createElement("span", createObj(Helpers_combineClasses("label-text", singleton(["children", "Finish Date"])))), createElement("input", {
                            type: "date",
                            className: "input input-bordered input-sm",
                            value: defaultArg((input_1 = state_1.FinishDate, (input_1 == null) ? undefined : toString_1(input_1, "yyyy-MM-dd")), ""),
                            disabled: !state_1.IsScheduleActive,
                            onInput: (e_3) => {
                                dispatch(new Msg(7, [parse_1(e_3.target.value)]));
                            },
                        })]), createElement("div", {
                            className: "form-control",
                            children: reactApi.Children.toArray(Array.from(children_10)),
                        }))), delay(() => {
                            let children_14, elems_4;
                            return append(singleton_1((children_14 = ofArray([createElement("span", createObj(Helpers_combineClasses("label-text", singleton(["children", "Repeat Pattern"])))), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["value", state_1.RepeatPatternName], ["disabled", !state_1.IsScheduleActive], ["onChange", (ev_1) => {
                                dispatch(new Msg(2, [ev_1.target.value]));
                            }], (elems_4 = toList(delay(() => map((repeatPattern) => createElement("option", {
                                value: name_74(repeatPattern),
                                children: name_74(repeatPattern),
                            }), RepeatPatternModule_repeatPatterns))), ["children", reactApi.Children.toArray(Array.from(elems_4))])]))))]), createElement("div", {
                                className: "form-control",
                                children: reactApi.Children.toArray(Array.from(children_14)),
                            }))), delay(() => {
                                let children_18, elems_7, elems_14, elems_11, elems_9, elems_10, elems_13, elems_12;
                                const matchValue = state_1.RepeatPattern;
                                switch (matchValue.tag) {
                                    case 1:
                                        return singleton_1((children_18 = ofArray([createElement("span", createObj(Helpers_combineClasses("label-text", singleton(["children", "Days of the week"])))), createElement("div", createObj(ofArray([["className", "grid grid-cols-1 flex flex-wrap gap-2 align-text-center"], (elems_7 = toList(delay(() => collect((dayOfWeek) => {
                                            let elems_6;
                                            const dayOfWeekStr = DayOfWeek_toString(dayOfWeek);
                                            return singleton_1(createElement("div", createObj(ofArray([["className", "flex items-center gap-1"], (elems_6 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["value", dayOfWeekStr], ["checked", contains(dayOfWeek, matchValue.fields[0], {
                                                Equals: (x_2, y) => (x_2 === y),
                                                GetHashCode: numberHash,
                                            })], ["disabled", !state_1.IsScheduleActive], ["onChange", (ev_2) => {
                                                dispatch(new Msg(3, [[dayOfWeek, ev_2.target.checked]]));
                                            }]])))), createElement("span", {
                                                className: "label-text",
                                                children: dayOfWeekStr,
                                            })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                                        }, [1, 2, 3, 4, 5, 6, 0]))), ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]), createElement("div", {
                                            className: "form-control",
                                            children: reactApi.Children.toArray(Array.from(children_18)),
                                        })));
                                    case 2: {
                                        const x_3 = matchValue.fields[0];
                                        const isRepeatOnLastDay = x_3.tag === 1;
                                        const patternInput_1 = (x_3.tag === 0) ? [true, x_3.fields[0]] : [false, 1];
                                        const dayOfMonth = patternInput_1[1] | 0;
                                        return singleton_1(createElement("div", createObj(ofArray([["className", "mt-4"], (elems_14 = [createElement("div", createObj(ofArray([["className", "flex flex-row vertical-center"], (elems_11 = [createElement("div", createObj(ofArray([["className", "mt-1/2 mr-2"], (elems_9 = [createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "radio-xs"], ["name", "monthly-repeat-pattern"], ["checked", patternInput_1[0]], ["disabled", !state_1.IsScheduleActive], ["onChange", (ev_3) => {
                                            const _arg_2 = ev_3.target.checked;
                                            dispatch(new Msg(4, [new DayOfMonth(0, [dayOfMonth])]));
                                        }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", {
                                            className: "mr-2",
                                            children: "On day",
                                        }), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["value", int32ToString(dayOfMonth)], ["disabled", !state_1.IsScheduleActive], ["onChange", (ev_4) => {
                                            dispatch(new Msg(4, [new DayOfMonth(0, [parse_2(ev_4.target.value, 511, false, 32)])]));
                                        }], (elems_10 = toList(delay(() => map((day) => createElement("option", {
                                            value: day,
                                            children: day,
                                        }), rangeDouble(1, 1, daysInMonth(year(state_1.StartDate), month(state_1.StartDate)))))), ["children", reactApi.Children.toArray(Array.from(elems_10))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", "flex flex-row vertical-center"], (elems_13 = [createElement("div", createObj(ofArray([["className", "mt-1/2 mr-2"], (elems_12 = [createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "radio-xs"], ["name", "monthly-repeat-pattern"], ["checked", isRepeatOnLastDay], ["disabled", !state_1.IsScheduleActive], ["onChange", (ev_5) => {
                                            const _arg_3 = ev_5.target.checked;
                                            dispatch(new Msg(5, []));
                                        }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", {
                                            children: "On last day of the month",
                                        })], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", reactApi.Children.toArray(Array.from(elems_14))])]))));
                                    }
                                    default:
                                        return singleton_1(react.createElement(react.Fragment, {}));
                                }
                            }));
                        }));
                    }));
                }))));
            }))));
        })), ["children", reactApi.Children.toArray(Array.from(elems_15))])])))), delay(() => {
            const matchValue_1 = props.Body;
            return (matchValue_1 == null) ? singleton_1(react.createElement(react.Fragment, {})) : singleton_1(matchValue_1);
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_16))]))))));
}

