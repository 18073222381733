import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, int32_type, anonRecord_type, bool_type, union_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class ScheduleParent extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Job"];
    }
}

export function ScheduleParent_$reflection() {
    return union_type("Domain.Entity.ScheduleHandler.ScheduleParent", [], ScheduleParent, () => [[["Item", class_type("System.Guid")]]]);
}

export function ScheduleParentModule_toInt(_arg) {
    return 10;
}

export class ScheduleActionType extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SimproGenerateQAPdf"];
    }
}

export function ScheduleActionType_$reflection() {
    return union_type("Domain.Entity.ScheduleHandler.ScheduleActionType", [], ScheduleActionType, () => [[["Item", anonRecord_type(["IsPdfPostedToSimpro", bool_type], ["JobId", class_type("System.Guid")], ["WasPdfGenerated", bool_type])]]]);
}

export function ScheduleActionTypeModule_toInt(_arg) {
    return 10;
}

export class ScheduleHandlerMessage extends Record {
    constructor(ScheduleTime, ScheduleActionType, OrgId, JobId, QAPdfId) {
        super();
        this.ScheduleTime = ScheduleTime;
        this.ScheduleActionType = (ScheduleActionType | 0);
        this.OrgId = OrgId;
        this.JobId = JobId;
        this.QAPdfId = QAPdfId;
    }
}

export function ScheduleHandlerMessage_$reflection() {
    return record_type("Domain.Entity.ScheduleHandler.ScheduleHandlerMessage", [], ScheduleHandlerMessage, () => [["ScheduleTime", class_type("System.TimeOnly")], ["ScheduleActionType", int32_type], ["OrgId", class_type("System.Guid")], ["JobId", class_type("System.Nullable`1", [class_type("System.Guid")])], ["QAPdfId", class_type("System.Nullable`1", [class_type("System.Guid")])]]);
}

export class ScheduleHandlerLog extends Record {
    constructor(Id, ScheduleParent, ScheduleActionType, ScheduleTime, LastScheduleActionedDateTime) {
        super();
        this.Id = Id;
        this.ScheduleParent = ScheduleParent;
        this.ScheduleActionType = ScheduleActionType;
        this.ScheduleTime = ScheduleTime;
        this.LastScheduleActionedDateTime = LastScheduleActionedDateTime;
    }
}

export function ScheduleHandlerLog_$reflection() {
    return record_type("Domain.Entity.ScheduleHandler.ScheduleHandlerLog", [], ScheduleHandlerLog, () => [["Id", class_type("System.Guid")], ["ScheduleParent", ScheduleParent_$reflection()], ["ScheduleActionType", ScheduleActionType_$reflection()], ["ScheduleTime", class_type("System.TimeOnly")], ["LastScheduleActionedDateTime", class_type("System.DateTimeOffset")]]);
}

