import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { decimal_type, int64_type, list_type, option_type, string_type, record_type, bool_type, enum_type, int32_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { JobShiftCheckTemplate, JobShiftCheckItemName, UserJobOverrides, UserJobOverride, WorkerDurationByJob, WorkersAtJob, WorkforceSkill, WorkerJobAllocation, JobUserSkill, JobPayrollDetail, JobSearchResult, JobDetail, Job, JobShiftCheckItem, SimproJobDetail, JobShiftScheduleOverride, JobShiftSchedule } from "../Entity/Job.fs.js";
import { SimproLookup_$reflection } from "../Entity/Simpro.fs.js";
import { AddressRawDtoModule_ofAddress, AddressRawDto_$reflection, AddressDtoModule_toAddress, AddressDtoModule_ofAddress, AddressDto_$reflection } from "./Address.fs.js";
import { Schedule_$reflection } from "../Entity/Schedule.fs.js";
import { map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { map as map_1 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { fromDate, toUniversalTime } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { LocationDtoModule_toLocationEx, LocationDtoModule_ofLocationEx, LocationDto_$reflection } from "./RememberWhere.fs.js";
import { LeaveRequestDtoModule_toLeaveRequest, LeaveRequestDtoModule_ofLeaveRequest, LeaveRequestDto_$reflection } from "./User.fs.js";

export class JobShiftScheduleDto extends Record {
    constructor(Id, JobId, DayOfWeek, ShiftStart, ShiftEnd, IsEnabled) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.DayOfWeek = (DayOfWeek | 0);
        this.ShiftStart = ShiftStart;
        this.ShiftEnd = ShiftEnd;
        this.IsEnabled = IsEnabled;
    }
}

export function JobShiftScheduleDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftScheduleDto", [], JobShiftScheduleDto, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["DayOfWeek", enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]])], ["ShiftStart", class_type("System.TimeOnly")], ["ShiftEnd", class_type("System.TimeOnly")], ["IsEnabled", bool_type]]);
}

export function JobShiftScheduleDtoModule_ofJobShiftSchedule(jobShiftSchedule) {
    return new JobShiftScheduleDto(jobShiftSchedule.Id, jobShiftSchedule.JobId, jobShiftSchedule.DayOfWeek, jobShiftSchedule.ShiftStart, jobShiftSchedule.ShiftEnd, jobShiftSchedule.IsEnabled);
}

export function JobShiftScheduleDtoModule_toJobShiftSchedule(jobShiftSchedule) {
    return new JobShiftSchedule(jobShiftSchedule.Id, jobShiftSchedule.JobId, jobShiftSchedule.DayOfWeek, jobShiftSchedule.ShiftStart, jobShiftSchedule.ShiftEnd, jobShiftSchedule.IsEnabled);
}

export class JobShiftScheduleOverrideDto extends Record {
    constructor(Id, JobId, IsEnabled, IsHoliday, DateTime, ShiftStart, ShiftEnd, Reason) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.IsEnabled = IsEnabled;
        this.IsHoliday = IsHoliday;
        this.DateTime = DateTime;
        this.ShiftStart = ShiftStart;
        this.ShiftEnd = ShiftEnd;
        this.Reason = Reason;
    }
}

export function JobShiftScheduleOverrideDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftScheduleOverrideDto", [], JobShiftScheduleOverrideDto, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["IsEnabled", bool_type], ["IsHoliday", bool_type], ["DateTime", class_type("System.DateTime")], ["ShiftStart", class_type("System.TimeOnly")], ["ShiftEnd", class_type("System.TimeOnly")], ["Reason", string_type]]);
}

export function JobShiftScheduleOverrideDtoModule_ofJobShiftScheduleOverride(jobShiftScheduleOverride) {
    return new JobShiftScheduleOverrideDto(jobShiftScheduleOverride.Id, jobShiftScheduleOverride.JobId, jobShiftScheduleOverride.IsEnabled, jobShiftScheduleOverride.IsHoliday, jobShiftScheduleOverride.DateTime, jobShiftScheduleOverride.ShiftStart, jobShiftScheduleOverride.ShiftEnd, jobShiftScheduleOverride.Reason);
}

export function JobShiftScheduleOverrideDtoModule_toJobShiftScheduleOverride(jobShiftScheduleOverride) {
    return new JobShiftScheduleOverride(jobShiftScheduleOverride.Id, jobShiftScheduleOverride.JobId, jobShiftScheduleOverride.IsEnabled, jobShiftScheduleOverride.IsHoliday, jobShiftScheduleOverride.DateTime, jobShiftScheduleOverride.ShiftStart, jobShiftScheduleOverride.ShiftEnd, jobShiftScheduleOverride.Reason);
}

export class SimproJobDetailDto extends Record {
    constructor(JobId, CompanyId, Description, SectionId, CostCentreId, ExpenseSectionId, ExpenseCostCentreId, Sections, SectionCostCentres, SectionExpenseCostCentres, QaUploadFolderId, JobAttachmentFolders) {
        super();
        this.JobId = (JobId | 0);
        this.CompanyId = (CompanyId | 0);
        this.Description = Description;
        this.SectionId = (SectionId | 0);
        this.CostCentreId = (CostCentreId | 0);
        this.ExpenseSectionId = ExpenseSectionId;
        this.ExpenseCostCentreId = ExpenseCostCentreId;
        this.Sections = Sections;
        this.SectionCostCentres = SectionCostCentres;
        this.SectionExpenseCostCentres = SectionExpenseCostCentres;
        this.QaUploadFolderId = QaUploadFolderId;
        this.JobAttachmentFolders = JobAttachmentFolders;
    }
}

export function SimproJobDetailDto_$reflection() {
    return record_type("Domain.Dto.Job.SimproJobDetailDto", [], SimproJobDetailDto, () => [["JobId", int32_type], ["CompanyId", int32_type], ["Description", string_type], ["SectionId", int32_type], ["CostCentreId", int32_type], ["ExpenseSectionId", option_type(int32_type)], ["ExpenseCostCentreId", option_type(int32_type)], ["Sections", list_type(SimproLookup_$reflection())], ["SectionCostCentres", list_type(SimproLookup_$reflection())], ["SectionExpenseCostCentres", list_type(SimproLookup_$reflection())], ["QaUploadFolderId", option_type(int32_type)], ["JobAttachmentFolders", list_type(SimproLookup_$reflection())]]);
}

export function SimproJobDetailDtoModule_ofSimproJobDetail(simproJobDetail) {
    return new SimproJobDetailDto(simproJobDetail.JobId, simproJobDetail.CompanyId, simproJobDetail.Description, simproJobDetail.SectionId, simproJobDetail.CostCentreId, simproJobDetail.ExpenseSectionId, simproJobDetail.ExpenseCostCentreId, simproJobDetail.Sections, simproJobDetail.SectionCostCentres, simproJobDetail.SectionExpenseCostCentres, simproJobDetail.QaUploadFolderId, simproJobDetail.JobAttachmentFolders);
}

export function SimproJobDetailDtoModule_toSimproJobDetail(simproJobDetail) {
    return new SimproJobDetail(simproJobDetail.JobId, simproJobDetail.CompanyId, simproJobDetail.Description, simproJobDetail.SectionId, simproJobDetail.CostCentreId, simproJobDetail.ExpenseSectionId, simproJobDetail.ExpenseCostCentreId, simproJobDetail.Sections, simproJobDetail.SectionCostCentres, simproJobDetail.SectionExpenseCostCentres, simproJobDetail.QaUploadFolderId, simproJobDetail.JobAttachmentFolders);
}

export class JobShiftCheckItemDto extends Record {
    constructor(Id, Name, Description, Priority, Created, Updated) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function JobShiftCheckItemDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftCheckItemDto", [], JobShiftCheckItemDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Created", class_type("System.DateTimeOffset")], ["Updated", class_type("System.DateTimeOffset")]]);
}

export function JobShiftCheckItemDtoModule_ofJobShiftCheckItem(jobShiftCheckItem) {
    return new JobShiftCheckItemDto(jobShiftCheckItem.Id, jobShiftCheckItem.Name, jobShiftCheckItem.Description, jobShiftCheckItem.Priority, jobShiftCheckItem.Created, jobShiftCheckItem.Updated);
}

export function JobShiftCheckItemDtoModule_toJobShiftCheckItem(jobShiftCheckItem) {
    return new JobShiftCheckItem(jobShiftCheckItem.Id, jobShiftCheckItem.Name, jobShiftCheckItem.Description, jobShiftCheckItem.Priority, jobShiftCheckItem.Created, jobShiftCheckItem.Updated);
}

export class JobDto extends Record {
    constructor(Id, CustomerId, SiteManagers, Title, StartDate, EndDate, Address, InvoiceFrequency, InvoiceStartDate, ShiftStartGeoFenceRadius, ShiftStartNotificationEnabled, ShiftStartNotificationMessage, ShiftFinishNotificationEnabled, ShiftFinishNotificationMessage, JobShiftSchedules, SimproConnectionDetailId, SimproJobDetail, IsUsingShiftPreCheck, IsUsingShiftPostCheck, JobShiftPreCheckItems, JobShiftPostCheckItems, IsActive, IsSimproQAUploadScheduledActive, SimproQAUploadSchedule, Created, Modified) {
        super();
        this.Id = Id;
        this.CustomerId = CustomerId;
        this.SiteManagers = SiteManagers;
        this.Title = Title;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Address = Address;
        this.InvoiceFrequency = (InvoiceFrequency | 0);
        this.InvoiceStartDate = InvoiceStartDate;
        this.ShiftStartGeoFenceRadius = (ShiftStartGeoFenceRadius | 0);
        this.ShiftStartNotificationEnabled = ShiftStartNotificationEnabled;
        this.ShiftStartNotificationMessage = ShiftStartNotificationMessage;
        this.ShiftFinishNotificationEnabled = ShiftFinishNotificationEnabled;
        this.ShiftFinishNotificationMessage = ShiftFinishNotificationMessage;
        this.JobShiftSchedules = JobShiftSchedules;
        this.SimproConnectionDetailId = SimproConnectionDetailId;
        this.SimproJobDetail = SimproJobDetail;
        this.IsUsingShiftPreCheck = IsUsingShiftPreCheck;
        this.IsUsingShiftPostCheck = IsUsingShiftPostCheck;
        this.JobShiftPreCheckItems = JobShiftPreCheckItems;
        this.JobShiftPostCheckItems = JobShiftPostCheckItems;
        this.IsActive = IsActive;
        this.IsSimproQAUploadScheduledActive = IsSimproQAUploadScheduledActive;
        this.SimproQAUploadSchedule = SimproQAUploadSchedule;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function JobDto_$reflection() {
    return record_type("Domain.Dto.Job.JobDto", [], JobDto, () => [["Id", class_type("System.Guid")], ["CustomerId", class_type("System.Guid")], ["SiteManagers", list_type(class_type("System.Guid"))], ["Title", string_type], ["StartDate", class_type("System.DateTimeOffset")], ["EndDate", class_type("System.DateTimeOffset")], ["Address", AddressDto_$reflection()], ["InvoiceFrequency", enum_type("Domain.Entity.Job.InvoiceFrequency", int32_type, [["Weekly", 0], ["Fortnightly", 1], ["Monthly", 2], ["Yearly", 3]])], ["InvoiceStartDate", option_type(class_type("System.DateTimeOffset"))], ["ShiftStartGeoFenceRadius", int32_type], ["ShiftStartNotificationEnabled", bool_type], ["ShiftStartNotificationMessage", string_type], ["ShiftFinishNotificationEnabled", bool_type], ["ShiftFinishNotificationMessage", string_type], ["JobShiftSchedules", list_type(JobShiftScheduleDto_$reflection())], ["SimproConnectionDetailId", option_type(class_type("System.Guid"))], ["SimproJobDetail", option_type(SimproJobDetailDto_$reflection())], ["IsUsingShiftPreCheck", bool_type], ["IsUsingShiftPostCheck", bool_type], ["JobShiftPreCheckItems", list_type(JobShiftCheckItemDto_$reflection())], ["JobShiftPostCheckItems", list_type(JobShiftCheckItemDto_$reflection())], ["IsActive", bool_type], ["IsSimproQAUploadScheduledActive", bool_type], ["SimproQAUploadSchedule", option_type(Schedule_$reflection())], ["Created", class_type("System.DateTime")], ["Modified", option_type(class_type("System.DateTime"))]]);
}

export function JobDtoModule_ofJob(job) {
    return new JobDto(job.Id, job.CustomerId, job.SiteManagers, job.Title, job.StartDate, job.EndDate, AddressDtoModule_ofAddress(job.Address), job.InvoiceFrequency, job.InvoiceStartDate, job.ShiftStartGeoFenceRadius, job.ShiftStartNotificationEnabled, job.ShiftStartNotificationMessage, job.ShiftFinishNotificationEnabled, job.ShiftFinishNotificationMessage, map(JobShiftScheduleDtoModule_ofJobShiftSchedule, job.JobShiftSchedules), job.SimproConnectionDetailId, map_1(SimproJobDetailDtoModule_ofSimproJobDetail, job.SimproJobDetail), job.IsUsingShiftPreCheck, job.IsUsingShiftPostCheck, map(JobShiftCheckItemDtoModule_ofJobShiftCheckItem, job.JobShiftPreCheckItems), map(JobShiftCheckItemDtoModule_ofJobShiftCheckItem, job.JobShiftPostCheckItems), job.IsActive, job.IsSimproQAUploadScheduleActive, job.SimproQAUploadSchedule, job.Created, job.Modified);
}

export function JobDtoModule_toJob(job) {
    return new Job(job.Id, job.CustomerId, job.SiteManagers, job.Title, job.StartDate, job.EndDate, AddressDtoModule_toAddress(job.CustomerId, job.Address), job.InvoiceFrequency, job.InvoiceStartDate, job.ShiftStartGeoFenceRadius, job.ShiftStartNotificationEnabled, job.ShiftStartNotificationMessage, job.ShiftFinishNotificationEnabled, job.ShiftFinishNotificationMessage, map(JobShiftScheduleDtoModule_toJobShiftSchedule, job.JobShiftSchedules), job.IsActive, job.SimproConnectionDetailId, map_1(SimproJobDetailDtoModule_toSimproJobDetail, job.SimproJobDetail), job.IsUsingShiftPreCheck, job.IsUsingShiftPostCheck, map(JobShiftCheckItemDtoModule_toJobShiftCheckItem, job.JobShiftPreCheckItems), map(JobShiftCheckItemDtoModule_toJobShiftCheckItem, job.JobShiftPostCheckItems), job.IsSimproQAUploadScheduledActive, job.SimproQAUploadSchedule, job.Created, job.Modified);
}

export class JobDetailDto extends Record {
    constructor(Id, Title, StartDate, FinishDate) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
    }
}

export function JobDetailDto_$reflection() {
    return record_type("Domain.Dto.Job.JobDetailDto", [], JobDetailDto, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["StartDate", class_type("System.DateTimeOffset")], ["FinishDate", class_type("System.DateTimeOffset")]]);
}

export function JobDetailDtoModule_ofJobDetail(jobDetail) {
    return new JobDetailDto(jobDetail.Id, jobDetail.Title, jobDetail.StartDate, jobDetail.FinishDate);
}

export function JobDetailDtoModule_toJobDetail(jobDetail) {
    return new JobDetail(jobDetail.Id, jobDetail.Title, jobDetail.StartDate, jobDetail.FinishDate);
}

export class JobSearchResultDto extends Record {
    constructor(BusinessName, Job, RequiredUserAllocationCount, AllocatedUserCount) {
        super();
        this.BusinessName = BusinessName;
        this.Job = Job;
        this.RequiredUserAllocationCount = RequiredUserAllocationCount;
        this.AllocatedUserCount = AllocatedUserCount;
    }
}

export function JobSearchResultDto_$reflection() {
    return record_type("Domain.Dto.Job.JobSearchResultDto", [], JobSearchResultDto, () => [["BusinessName", string_type], ["Job", JobDto_$reflection()], ["RequiredUserAllocationCount", int64_type], ["AllocatedUserCount", int64_type]]);
}

export function JobSearchResultDtoModule_ofJobSearchResult(jobSearchResult) {
    return new JobSearchResultDto(jobSearchResult.BusinessName, JobDtoModule_ofJob(jobSearchResult.Job), jobSearchResult.RequiredUserAllocationCount, jobSearchResult.AllocatedUserCount);
}

export function JobSearchResultDtoModule_toJobSearchResult(jobSearchResult) {
    return new JobSearchResult(jobSearchResult.BusinessName, JobDtoModule_toJob(jobSearchResult.Job), jobSearchResult.RequiredUserAllocationCount, jobSearchResult.AllocatedUserCount);
}

export class JobShiftScheduleRawDto extends Record {
    constructor(IsEnabled, DayOfWeek, ShiftStart, ShiftEnd) {
        super();
        this.IsEnabled = IsEnabled;
        this.DayOfWeek = (DayOfWeek | 0);
        this.ShiftStart = ShiftStart;
        this.ShiftEnd = ShiftEnd;
    }
}

export function JobShiftScheduleRawDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftScheduleRawDto", [], JobShiftScheduleRawDto, () => [["IsEnabled", bool_type], ["DayOfWeek", enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]])], ["ShiftStart", class_type("System.TimeOnly")], ["ShiftEnd", class_type("System.TimeOnly")]]);
}

export class JobShiftCheckItemRawDto extends Record {
    constructor(Id, Name, Description, Priority, Created, Updated) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function JobShiftCheckItemRawDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftCheckItemRawDto", [], JobShiftCheckItemRawDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")]]);
}

export function JobShiftCheckItemRawDtoModule_ofJobShiftCheckItem(jobShiftCheckItem) {
    return new JobShiftCheckItemRawDto(jobShiftCheckItem.Id, jobShiftCheckItem.Name, jobShiftCheckItem.Description, jobShiftCheckItem.Priority, toUniversalTime(jobShiftCheckItem.Created), toUniversalTime(jobShiftCheckItem.Updated));
}

export function JobShiftCheckItemRawDtoModule_toJobShiftCheckItem(jobShiftCheckItem) {
    return new JobShiftCheckItem(jobShiftCheckItem.Id, jobShiftCheckItem.Name, jobShiftCheckItem.Description, jobShiftCheckItem.Priority, fromDate(jobShiftCheckItem.Created), fromDate(jobShiftCheckItem.Updated));
}

export class JobRawDto extends Record {
    constructor(Id, CustomerId, Title, StartDate, EndDate, JobOverrideDate, Address, ShiftStartGeoFenceRadius, ShiftStartNotificationEnabled, ShiftStartNotificationMessage, ShiftFinishNotificationEnabled, ShiftFinishNotificationMessage, JobShiftSchedules, IsShiftStartAndFinishOutsideGeofenceAllowed, IsShiftStartAndFinishOutsideScheduleAllowed, IsUsingShiftPreCheck, IsUsingShiftPostCheck, JobShiftPreCheckItems, JobShiftPostCheckItems) {
        super();
        this.Id = Id;
        this.CustomerId = CustomerId;
        this.Title = Title;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.JobOverrideDate = JobOverrideDate;
        this.Address = Address;
        this.ShiftStartGeoFenceRadius = (ShiftStartGeoFenceRadius | 0);
        this.ShiftStartNotificationEnabled = ShiftStartNotificationEnabled;
        this.ShiftStartNotificationMessage = ShiftStartNotificationMessage;
        this.ShiftFinishNotificationEnabled = ShiftFinishNotificationEnabled;
        this.ShiftFinishNotificationMessage = ShiftFinishNotificationMessage;
        this.JobShiftSchedules = JobShiftSchedules;
        this.IsShiftStartAndFinishOutsideGeofenceAllowed = IsShiftStartAndFinishOutsideGeofenceAllowed;
        this.IsShiftStartAndFinishOutsideScheduleAllowed = IsShiftStartAndFinishOutsideScheduleAllowed;
        this.IsUsingShiftPreCheck = IsUsingShiftPreCheck;
        this.IsUsingShiftPostCheck = IsUsingShiftPostCheck;
        this.JobShiftPreCheckItems = JobShiftPreCheckItems;
        this.JobShiftPostCheckItems = JobShiftPostCheckItems;
    }
}

export function JobRawDto_$reflection() {
    return record_type("Domain.Dto.Job.JobRawDto", [], JobRawDto, () => [["Id", class_type("System.Guid")], ["CustomerId", class_type("System.Guid")], ["Title", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["JobOverrideDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["Address", AddressRawDto_$reflection()], ["ShiftStartGeoFenceRadius", int32_type], ["ShiftStartNotificationEnabled", bool_type], ["ShiftStartNotificationMessage", string_type], ["ShiftFinishNotificationEnabled", bool_type], ["ShiftFinishNotificationMessage", string_type], ["JobShiftSchedules", list_type(JobShiftScheduleRawDto_$reflection())], ["IsShiftStartAndFinishOutsideGeofenceAllowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleAllowed", bool_type], ["IsUsingShiftPreCheck", bool_type], ["IsUsingShiftPostCheck", bool_type], ["JobShiftPreCheckItems", list_type(JobShiftCheckItemRawDto_$reflection())], ["JobShiftPostCheckItems", list_type(JobShiftCheckItemRawDto_$reflection())]]);
}

export function JobRawDtoModule_ofJob(jobOverrideDate, isShiftAndFinishStartOutsideGeofenceAllowed, isShiftStartAndFinishOutsideScheduleAllowed, job) {
    return new JobRawDto(job.Id, job.CustomerId, job.Title, toUniversalTime(job.StartDate), toUniversalTime(job.EndDate), jobOverrideDate, AddressRawDtoModule_ofAddress(job.Address), job.ShiftStartGeoFenceRadius, job.ShiftStartNotificationEnabled, job.ShiftStartNotificationMessage, job.ShiftFinishNotificationEnabled, job.ShiftFinishNotificationMessage, map((x) => (new JobShiftScheduleRawDto(x.IsEnabled, x.DayOfWeek, x.ShiftStart, x.ShiftEnd)), job.JobShiftSchedules), isShiftAndFinishStartOutsideGeofenceAllowed, isShiftStartAndFinishOutsideScheduleAllowed, job.IsUsingShiftPreCheck, job.IsUsingShiftPostCheck, map(JobShiftCheckItemRawDtoModule_ofJobShiftCheckItem, job.JobShiftPreCheckItems), map(JobShiftCheckItemRawDtoModule_ofJobShiftCheckItem, job.JobShiftPostCheckItems));
}

export class JobPayrollDetailDto extends Record {
    constructor(Id, Title, BusinessName) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.BusinessName = BusinessName;
    }
}

export function JobPayrollDetailDto_$reflection() {
    return record_type("Domain.Dto.Job.JobPayrollDetailDto", [], JobPayrollDetailDto, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["BusinessName", string_type]]);
}

export function JobPayrollDetailDtoModule_ofJobPayrollDetail(jobPayrollDetail) {
    return new JobPayrollDetailDto(jobPayrollDetail.Id, jobPayrollDetail.Title, jobPayrollDetail.BusinessName);
}

export function JobPayrollDetailDtoModule_toJobPayrollDetail(jobPayrollDetail) {
    return new JobPayrollDetail(jobPayrollDetail.Id, jobPayrollDetail.Title, jobPayrollDetail.BusinessName);
}

export class JobUserSkillDto extends Record {
    constructor(Id, JobId, SkillId, UserCount, Rating, EstimatedPrice, AgreedPrice) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.SkillId = SkillId;
        this.UserCount = (UserCount | 0);
        this.Rating = Rating;
        this.EstimatedPrice = EstimatedPrice;
        this.AgreedPrice = AgreedPrice;
    }
}

export function JobUserSkillDto_$reflection() {
    return record_type("Domain.Dto.Job.JobUserSkillDto", [], JobUserSkillDto, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["SkillId", class_type("System.Guid")], ["UserCount", int32_type], ["Rating", option_type(enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]]))], ["EstimatedPrice", decimal_type], ["AgreedPrice", decimal_type]]);
}

export function JobUserSkillDtoModule_ofJobUserSkill(jobUserSkill) {
    return new JobUserSkillDto(jobUserSkill.Id, jobUserSkill.JobId, jobUserSkill.SkillId, jobUserSkill.UserCount, jobUserSkill.Rating, jobUserSkill.EstimatedPrice, jobUserSkill.AgreedPrice);
}

export function JobUserSkillDtoModule_toJobUserSkill(jobUserSkill) {
    return new JobUserSkill(jobUserSkill.Id, jobUserSkill.JobId, jobUserSkill.SkillId, jobUserSkill.UserCount, jobUserSkill.Rating, jobUserSkill.EstimatedPrice, jobUserSkill.AgreedPrice);
}

export class WorkerJobAllocationDto extends Record {
    constructor(JobName, TotalWorkersAllocated) {
        super();
        this.JobName = JobName;
        this.TotalWorkersAllocated = TotalWorkersAllocated;
    }
}

export function WorkerJobAllocationDto_$reflection() {
    return record_type("Domain.Dto.Job.WorkerJobAllocationDto", [], WorkerJobAllocationDto, () => [["JobName", string_type], ["TotalWorkersAllocated", int64_type]]);
}

export function WorkerJobAllocationDtoModule_ofWorkerJobAllocation(workerJobAllocation) {
    return new WorkerJobAllocationDto(workerJobAllocation.JobName, workerJobAllocation.TotalWorkersAllocated);
}

export function WorkerJobAllocationDtoModule_toWorkerJobAllocation(workerJobAllocation) {
    return new WorkerJobAllocation(workerJobAllocation.JobName, workerJobAllocation.TotalWorkersAllocated);
}

export class WorkforceSkillDto extends Record {
    constructor(Skill, WorkerCount) {
        super();
        this.Skill = Skill;
        this.WorkerCount = WorkerCount;
    }
}

export function WorkforceSkillDto_$reflection() {
    return record_type("Domain.Dto.Job.WorkforceSkillDto", [], WorkforceSkillDto, () => [["Skill", string_type], ["WorkerCount", int64_type]]);
}

export function WorkforceSkillDtoModule_ofWorkforceSkill(workforceSkill) {
    return new WorkforceSkillDto(workforceSkill.Skill, workforceSkill.WorkerCount);
}

export function WorkforceSkillDtoModule_toWorkforceSkill(workforceSkill) {
    return new WorkforceSkill(workforceSkill.Skill, workforceSkill.WorkerCount);
}

export class WorkersAtJobDto extends Record {
    constructor(JobName, JobLocation, WorkerCount) {
        super();
        this.JobName = JobName;
        this.JobLocation = JobLocation;
        this.WorkerCount = (WorkerCount | 0);
    }
}

export function WorkersAtJobDto_$reflection() {
    return record_type("Domain.Dto.Job.WorkersAtJobDto", [], WorkersAtJobDto, () => [["JobName", string_type], ["JobLocation", LocationDto_$reflection()], ["WorkerCount", int32_type]]);
}

export function WorkersAtJobDtoModule_ofWorkersAtJob(workersAtJob) {
    return new WorkersAtJobDto(workersAtJob.JobName, LocationDtoModule_ofLocationEx(workersAtJob.JobLocation), workersAtJob.WorkerCount);
}

export function WorkersAtJobDtoModule_toWorkersAtJob(workersAtJob) {
    return new WorkersAtJob(workersAtJob.JobName, LocationDtoModule_toLocationEx(workersAtJob.JobLocation), workersAtJob.WorkerCount);
}

export class WorkerDurationByJobDto extends Record {
    constructor(JobName, WorkerName, ShiftStart, ShiftFinish, TotalWorkDuration, TotalOvertimeDuration) {
        super();
        this.JobName = JobName;
        this.WorkerName = WorkerName;
        this.ShiftStart = ShiftStart;
        this.ShiftFinish = ShiftFinish;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function WorkerDurationByJobDto_$reflection() {
    return record_type("Domain.Dto.Job.WorkerDurationByJobDto", [], WorkerDurationByJobDto, () => [["JobName", string_type], ["WorkerName", string_type], ["ShiftStart", class_type("System.DateTime")], ["ShiftFinish", class_type("System.DateTime")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export function WorkerDurationByJobDtoModule_ofWorkerDurationByJob(workerDurationByJob) {
    return new WorkerDurationByJobDto(workerDurationByJob.JobName, workerDurationByJob.WorkerName, workerDurationByJob.ShiftStart, workerDurationByJob.ShiftFinish, workerDurationByJob.TotalWorkDuration, workerDurationByJob.TotalOvertimeDuration);
}

export function WorkerDurationByJobDtoModule_toWorkerDurationByJob(workerDurationByJob) {
    return new WorkerDurationByJob(workerDurationByJob.JobName, workerDurationByJob.WorkerName, workerDurationByJob.ShiftStart, workerDurationByJob.ShiftFinish, workerDurationByJob.TotalWorkDuration, workerDurationByJob.TotalOvertimeDuration);
}

export class UserJobOverrideDto extends Record {
    constructor(Id, OrgId, DateTime, JobId, UserId, Username) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.DateTime = DateTime;
        this.JobId = JobId;
        this.UserId = UserId;
        this.Username = Username;
    }
}

export function UserJobOverrideDto_$reflection() {
    return record_type("Domain.Dto.Job.UserJobOverrideDto", [], UserJobOverrideDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["DateTime", class_type("System.DateTimeOffset")], ["JobId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type]]);
}

export function UserJobOverrideDtoModule_ofUserJobOverride(userJobOverride) {
    return new UserJobOverrideDto(userJobOverride.Id, userJobOverride.OrgId, userJobOverride.DateTime, userJobOverride.JobId, userJobOverride.UserId, userJobOverride.Username);
}

export function UserJobOverrideDtoModule_toUserJobOverride(userJobOverride) {
    return new UserJobOverride(userJobOverride.Id, userJobOverride.OrgId, userJobOverride.DateTime, userJobOverride.JobId, userJobOverride.UserId, userJobOverride.Username);
}

export class UserJobOverridesDto extends Record {
    constructor(UserId, Username, UserIsActive, UserJobOverrides, UserLeaves) {
        super();
        this.UserId = UserId;
        this.Username = Username;
        this.UserIsActive = UserIsActive;
        this.UserJobOverrides = UserJobOverrides;
        this.UserLeaves = UserLeaves;
    }
}

export function UserJobOverridesDto_$reflection() {
    return record_type("Domain.Dto.Job.UserJobOverridesDto", [], UserJobOverridesDto, () => [["UserId", class_type("System.Guid")], ["Username", string_type], ["UserIsActive", bool_type], ["UserJobOverrides", list_type(UserJobOverrideDto_$reflection())], ["UserLeaves", list_type(LeaveRequestDto_$reflection())]]);
}

export function UserJobOverridesDtoModule_ofUserJobOverrides(userJobOverrides) {
    return new UserJobOverridesDto(userJobOverrides.UserId, userJobOverrides.Username, userJobOverrides.UserIsActive, map(UserJobOverrideDtoModule_ofUserJobOverride, userJobOverrides.UserJobOverrides), map(LeaveRequestDtoModule_ofLeaveRequest, userJobOverrides.UserLeaves));
}

export function UserJobOverridesDtoModule_toUserJobOverrides(userJobOverrides) {
    return new UserJobOverrides(userJobOverrides.UserId, userJobOverrides.Username, userJobOverrides.UserIsActive, map(UserJobOverrideDtoModule_toUserJobOverride, userJobOverrides.UserJobOverrides), map(LeaveRequestDtoModule_toLeaveRequest, userJobOverrides.UserLeaves));
}

export class JobShiftCheckItemNameDto extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function JobShiftCheckItemNameDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftCheckItemNameDto", [], JobShiftCheckItemNameDto, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export function JobShiftCheckItemNameDtoModule_ofJobShiftCheckItemName(jobShiftCheckItemName) {
    return new JobShiftCheckItemNameDto(jobShiftCheckItemName.Id, jobShiftCheckItemName.Name);
}

export function JobShiftCheckItemNameDtoModule_toJobShiftCheckItemName(jobShiftCheckItemName) {
    return new JobShiftCheckItemName(jobShiftCheckItemName.Id, jobShiftCheckItemName.Name);
}

export class JobShiftCheckTemplateDto extends Record {
    constructor(Id, Name, ShiftCheckItems) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ShiftCheckItems = ShiftCheckItems;
    }
}

export function JobShiftCheckTemplateDto_$reflection() {
    return record_type("Domain.Dto.Job.JobShiftCheckTemplateDto", [], JobShiftCheckTemplateDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["ShiftCheckItems", list_type(JobShiftCheckItemDto_$reflection())]]);
}

export function JobShiftCheckTemplateDtoModule_ofJobShiftCheckTemplate(jobShiftCheckTemplate) {
    return new JobShiftCheckTemplateDto(jobShiftCheckTemplate.Id, jobShiftCheckTemplate.Name, map(JobShiftCheckItemDtoModule_ofJobShiftCheckItem, jobShiftCheckTemplate.ShiftCheckItems));
}

export function JobShiftCheckTemplateDtoModule_toJobShiftCheckTemplate(jobShiftCheckTemplate) {
    return new JobShiftCheckTemplate(jobShiftCheckTemplate.Id, jobShiftCheckTemplate.Name, map(JobShiftCheckItemDtoModule_toJobShiftCheckItem, jobShiftCheckTemplate.ShiftCheckItems));
}

